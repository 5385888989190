/* Buttons */
.btn {
  font-size: $btn-font-size;
  line-height: 1;
  font-family: $type-1;
  text-transform: uppercase;
  font-weight: $f-black;
  font-size: 14px;
  line-height: 1;
  background: transparent;
  border: none;
  color: #555;
  display: inline-flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  span {
    flex: 8;
  }
  svg {
    color: $white;
  }
  &__download {
    background-color: transparent;
  }
  &--green {
    background-color: $success;
    border: 1px solid $success;
    color: $white;
    @include on-event {
      background-color: $white;
      svg {
        color: $success !important;
      }
      span {
        color: $success;
      }
    }
  }
  &--dark_blue {
    background-color: #0000F0;
    border: 1px solid #0000F0;
    color: $white;
    @include on-event {
      background-color: $white;
      svg {
        color: #0000F0;
      }
      span {
        color: #0000F0;
      }
    }
  }
  &--purple {
    background-color: $info;
    border: 1px solid $info;
    color: $white;
    @include on-event {
      background-color: $white;
      svg {
        color: $info;
      }
      span {
        color: $info;
      }
    }
  }
  &--orange {
    background-color: $warning;
    border: 1px solid $warning;
    color: $white;
    @include on-event {
      background-color: $white;
      svg {
        color: $warning;
      }
      span {
        color: $warning;
      }
    }
  }
  &--blue {
    background-color: $primary;
    border: 1px solid $primary;
    color: $white;
    @include on-event {
      background-color: $white;
      svg {
        color: $primary;
      }
      span {
        color: $primary;
      }
    }
  }
  &--blue-border {
    background-color: $white;
    border: 1px solid $primary;
    span {
      color: $primary;
    }
    svg {
      color: $primary !important;
    }
    @include on-event {
      background-color: $primary;
      svg {
        color: $white;
      }
      span {
        color: $white;
      }
    }
  }
  &.btn-danger {
    background-color: $danger;
    border: 1px solid $danger;
    color: $white;
    @include on-event {
      background-color: $white;
      color: $danger;
      svg {
        color: $danger ;
      }
      span {
        color: $danger;
      }
    }
  }
  &.btn-success {
    background-color: $success;
    border: 1px solid $success;
    color: $white;
    @include on-event {
      background-color: $white;
      color: $success;
      svg {
        color: $success;
      }
      span {
        color: $success;
      }
    }
  }
  &.btn-warning {
    background-color: $warning;
    border: 1px solid $warning;
    color: $white;
    @include on-event {
      background-color: $white;
      color: $warning;
      svg {
        color: $warning;
      }
      span {
        color: $warning;
      }
    }
  }
  &.btn-info {
    background-color: $primary;
    border: 1px solid $primary;
    color: $white;
    @include on-event {
      background-color: $white;
      svg {
        color: $primary;
      }
      span {
        color: $primary;
      }
    }
  }
  &.btn-link {
    border-radius: 0px;
    border-bottom: 3px solid $white;
    @include on-event {
      background: transparent !important;
      border-bottom: 3px solid $black;
      box-shadow: none;
      -webkit-box-shadow: none;
      svg {
        color: $black !important;
      }
      span {
        color: $black !important;
      }
    }

  }
  svg {
    margin-right: 0.3125rem;
  }
  .btn-label {
    &:before {
      font-size: 1rem;
      line-height: 5px;
      vertical-align: middle;
    }
    &.btn-label-left {
      margin-right: 5px;
    }
    &.btn-label-right {
      margin-left: 5px;
    }
  }
  &.btn-rounded {
    border-radius: 50px;
  }
  &.btn-icons {
    width: 40px;
    height: 40px;
    padding: 10px;
    text-align: center;
    vertical-align: middle;
    svg {
      margin: auto;
      line-height: initial;
    }
  }
  &.btn-fw {
    min-width: $button-fixed-width;
  }
  &.icon-btn {
    svg {
      margin-right: 0;
    }
  }
  &.social-btn {
    padding: $social-btn-padding;
    svg {
      margin-right: 0;
      font-size: $social-btn-icon-size;
    }
  }
  &.btn-sm {
    font-size: $btn-font-size-sm;
  }
  &.btn-lg {
    font-size: $btn-font-size-lg;
  }
  &.btn-xs {
    padding: $btn-padding-y-xs $btn-padding-x-xs;
    font-size: $btn-font-size-xs;
    margin-bottom: 0.25rem !important;
  }
  &.btn-transparent {
    background: transparent;
  }
  &.btn-danger,
  &.btn-info,
  &.btn-success,
  &.btn-teal,
  &.btn-warning {
    color: $white;
  }
  &.btn-outline-light {
    border: 1px solid;
    border-color: darken(theme-color(light), 15%);
    color: darken(theme-color(light), 15%);
  }
  &.btn-outline-secondary {
    border: 1px solid;
    color: rgba($black, 0.5);
  }
  &.btn-inverse-secondary {
    background-color: rgba(theme-color(secondary), 0.5);
    color: rgba($black, 0.5);
    &:hover {
      color: rgba($black, 0.5);
    }
  }
  &.btn-inverse-light {
    background-color: $white;
    color: rgba($black, 0.5);
    border-color: lighten($black, 85%);
    &:hover {
      color: rgba($black, 0.5);
      border-color: lighten($black, 85%);
    }
  }
}

.btn-group {
  .btn {
    border-top: none;
    border-bottom: none;
    border-left: none;
    &:last-child {
      border-right: none;
    }
    &.btn-primary {
      border-color: darken(theme-color(primary), 3%);
    }
    &.btn-secondary {
      border-color: darken(theme-color(secondary), 3%);
    }
    &.btn-info {
      border-color: darken(theme-color(info), 3%);
    }
    &.btn-warning {
      border-color: darken(theme-color(warning), 3%);
    }
    &.btn-success {
      border-color: darken(theme-color(success), 3%);
    }
    &.btn-danger {
      border-color: darken(theme-color(danger), 3%);
    }
    &.btn-dark {
      border-color: darken(theme-color(dark), 3%);
    }
    &.btn-light {
      border-color: darken(theme-color(light), 3%);
    }
  }
}

.btn-toolbar {
  .btn-group {
    +.btn-group {
      @extend .ml-2;
    }
  }
}

.show_password.btn,
.generate_password.btn {
  color: $white;
  @include on-event {
    color: $primary;
    background-color: $white;
  }
}

/*social buttons*/

@each $color,
$value in $social-colors {
  .btn-#{$color} {
    @include social-button(social-color($color));
  }
}

/*social buttons*/

@each $color,
$value in $social-colors {
  .btn-social-outline-#{$color} {
    @include btn-social-outline-variant(social-color($color));
  }
}

/* inverse buttons */

@each $color,
$value in $theme-colors {
  .btn-inverse-#{$color} {
    @include button-inverse-variant($value);
  }
}

/* Inverse Outlined Buttons */

@each $color,
$value in $theme-colors {
  .btn-inverse-outline-#{$color} {
    @include button-inverse-outline-variant($value);
  }
}
