.block-tiles.row {
  margin: 0 -12.5px 25px;

  .col {
    padding: 0;
    width: 24.25%;
    margin: 0 1% 0 0;

    &:last-child {
      margin: 0;
    }
  }

  &.block-tiles-margin {
    margin: 0 0 30px;
  }

  @media only screen and (max-width: 959px) {
    margin: 0 auto;
    // flex-direction: column;
    display: block;
    .col-3,
    .col-2 {
      max-width: 100%;
    }
    .block-tile__colours svg {
      font-size: 80px;
    }
  }
}

.border__blue {
  border: 1px solid $primary;
  h5 {
    margin: 0 0 0.9375rem;
    font-size: 1.125rem;
  }
  p, svg {
    color: $primary !important;
  }
}

.btn-border {
  &__orange {
    border: 1px solid $warning;
  }
}

.widget-container{
  margin-bottom: 20px;
}

.block-tile {
  display: flex;
  padding: 0.75rem;
  text-align: center;
  @include border-radius(5px);
  @include transition(.4s);
  font-size: 0;
  height: 100%;
  min-height: 180px;
  width: 100%;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  //-webkit-box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
  //-moz-box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
  //box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
  @include on-event {
    p {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  p {
    //opacity: 0;
    //transform: translate(0, 50px);
    //transition: opacity 1s ease-in-out, transform 0.4s ease-in-out;
    margin: 0;
    color: $white;
    line-height: 1.2;
  }

  &__colours--black {
    background-color: #000;
  }

  &__colours--white {
    background-color: #FFF !important;

    span {
      color: #000 !important;
    }
  }


  &__account {

    .icon__static {
      position: static;
      width: auto !important;
      height: 80% !important;
      color: $success;
    }
    .block-tile__cta--numbers {
      font-size: 1.3rem !important;
      top: auto !important;
      bottom: 0 !important;
      transform: translate(-50%, 0) !important;
    }
  }

  &__account_upgrade {

    .icon__static {
      position: static;
      width: auto !important;
      height: 80% !important;
      color: $yellow;
    }
    .block-tile__cta--numbers {
      font-size: 22px !important;
      top: auto !important;
      bottom: 0 !important;
      transform: translate(-50%, 0) !important;
    }
  }


  &--link-wrap {
    @include on-event {
      text-decoration: none;
    }
  }

  a {
    font-size: 0.75rem;
    &.adminBlock {
      flex-direction: column;
      align-items: center;
      width: 100%;
      cursor: pointer;
      svg {
        margin: 15%;
      }
      .fa-layers {
        margin: 15%;
        svg {
          margin: 0;
        }
      }
    }
  }

  .btn {
    //font-size: 1rem;
    //padding: 1rem;
    width: 100%;
    &__download {
      border: none !important;
      padding: 0px;
      &:hover {
        background: inherit !important;
        box-shadow: none !important;
      }
    }
    &.adminBlock {
      flex-direction: column;
      align-items: center;
      width: 100%;
      cursor: pointer;
      svg {
        margin: 15%;
      }
    }

  }

  &__cta {
    &--error {
      &:hover {
        pointer-events: none;
      }
    }
    background-color: $white;
    //-webkit-box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
    //-moz-box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
    //box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
    @include rel;
    .btn span {
      word-break: break-all;
    }
    h4 {
      text-transform: uppercase;
      line-height: 1;
      color: $primary;
      flex: 1;
    }

    .icon {
      .fa-layers {
        svg.icon {
          color: rgba($white, 0.5);
        }
        svg.svg-inline--fa {
          margin: 0 auto;
          right: 0;
          left: 0;
          top: auto;
          bottom: auto;
        }
        svg.outline {
          color: $white !important;
        }
      }
      &--blue {
        color: $primary;
        @include on-event{
          color: $primary;
        }
      }

      &--orange {
        color: $warning;
        @include on-event{
          color: $warning;
        }
      }

      &--purple {
        color: $info;
        @include on-event{
          color: $info;
        }
      }

      &--red, &--danger {
        color: $danger;
        @include on-event{
          color: $danger;
        }

      }

      &--green, &--success {
        color: $success;
        @include on-event{
          color: $success;
        }

      }

      &--widget-green {
        color: $success;
        @include on-event{
          color: $success;
        }

      }

      &--dark-green {
        color: $success;
        @include on-event{
          color: $success;
        }

      }

      &--white {
        color: $white;
        @include on-event{
          color: $white;
        }
        svg.fa-inverse {
          color: $black !important;
          @include on-event{
            color: $black;
          }
        }
      }
    }


    &--numbers {
      font-size: 2.5rem;
      line-height: 1;
      margin: 1.7rem auto 1.7rem;
      font-weight: $f-black;
      color: $info;

      &--blue {
        color: $primary;
      }

      &--orange {
        color: $warning;
      }

      &--purple {
        color: $info;
      }

      &--red {
        color: $danger;
      }

      &--green {
        color: $success;
      }

      &--widget-green {
        color: $success;
      }

      &--dark-green {
        color: $success;
      }

      &--white {
        color: $white;
      }

    }

    &--account {
      .block-tile__cta--numbers {
        color: $primary;
      }
    }

    h5,
    .no-btn {
      margin: 0 0 0.9375rem;
      font-size: 1.125rem;
    }

    .currency {
      font-size: 40px;
      color: $main-font;
    }

    .no-btn {
      flex:1;
      display: block;
      text-decoration: none !important;
      color: $main-font !important;
      letter-spacing: 1.3px;
      @include on-event {
        text-decoration: none;
      }
      margin: 0 0 0.9375rem;
      font-size: 1rem;
      &--red {
        color: $danger !important;
      }
      &--green {
        color: $success !important;
      }
    }

    &--notification--wrapper {
      @include rel;
      height: 4.5rem;
      width: 4.5rem;
      @include border-radius(50%);
      display: block;
      margin: 10px auto 10px;
      text-align: center;

      &--white {
        width: 100%;
      }

      &--blue {
        background-color: $primary;
      }

      &--yellow {
        background-color: $yellow;
      }

      &--orange {
        background-color: $warning;
      }

      &--purple {
        background-color: $info;
      }

      &--red {
        background-color: $danger;
      }

      &--green {
        background-color: $success;
      }

      &--dark-green {
        background-color: $success;
      }

      .block-tile__cta--numbers {
        @include rel;
        background-color: transparent;
        font-size: 37px;
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0px;
        width: 100%;

        &--blue {
          color: $primary;
        }

        &--orange {
          color: $warning;
        }

        &--purple {
          color: $info;
        }

        &--red {
          color: $danger;
        }

        &--green {
          color: $success;
        }

        &--dark-green {
          color: $success;
        }

        &--white {
          color: $white;
        }

      }

      svg {
        //position: absolute;
        //top: 0.15rem;
        //left: -0.5rem;
        //height: 1.75rem !important;
        //width: 1.75rem !important;
        //font-size: 1.5rem !important;
        //background-color: inherit;
        //@include border-radius(50%);
        //color: $white;
        //padding: 5px;
        //border: 2px solid white;
      }
    }
  }

  h4.block-tile--error {
    font-size: 1.1rem;
    margin-top: 18px;
    color: $primary;
  }

  &__colours {
    &.no-pointer {
      box-shadow: none;
    }
    svg {
      color: rgba($white, 1);
    }

    img {
      display: var(--fa-display,inline-block);
      height: 1em;
      overflow: visible;
      vertical-align: -0.125em;
      font-size: 8em;
      margin: 15%;
    }

    h1 {
      color: $white;
      font-size: 50px;
      line-height: 1;
      margin: 0;
      font-weight: $f-black;
    }

    h4 {
      color: $white;
      text-transform: uppercase;
      line-height: 1;
      margin: 0;
      font-weight: $med;
      overflow-wrap: initial;
      &.block-tile--title {
        font-size: 2.2rem;
        color: $primary;
      }
    }

    button:not(.block-tile) {
      svg {
        margin: 20% !important;
      }
    }

    h6 {
      color: $white;
      line-height: 1;
      font-weight: $reg;

      a {
        text-decoration: underline;
        color: $white;
      }

    }

    &--blue {
      background: $primary;
      @include on-event {
        background: darken($primary, 10%)
      }

      h1 {
        color: $primary;
      }

      span.block-tile__cta--numbers {
        color: $primary;
      }
    }

    &--yellow {
      background: $yellow;
      @include on-event {
        background: darken($yellow, 10%)
      }

      h1 {
        color: $yellow;
      }

      span.block-tile__cta--numbers {
        color: $yellow;
      }
    }

    &--orange {
      background: $warning;
      @include on-event {
        background: darken($warning, 10%)
      }

      h1 {
        color: $warning;
      }

      span.block-tile__cta--numbers {
        color: $warning;
      }
    }

    &--purple {
      background: $info;
      @include on-event {
        background: darken($info, 10%)
      }

      h1 {
        color: $info;
      }

      span.block-tile__cta--numbers {
        color: $info;
      }
    }

    &--red {
      background: $danger;
      @include on-event {
        background: darken($danger, 10%)
      }

      h1 {
        color: $danger;
      }

      span.block-tile__cta--numbers {
        color: $danger;
      }
    }

    &--green {
      background: $success;
      @include on-event {
        background: darken($success, 10%)
      }

      h1 {
        color: $success;
      }

      span.block-tile__cta--numbers {
        color: $success;
      }
    }

    &--widget-green {
      background: $success;
      @include on-event {
        background: darken($success, 10%)
      }

      h1 {
        color: $success;
      }

      span.block-tile__cta--numbers {
        color: $success;
      }
    }


    &--dark-green {
      background: $success;
      @include on-event {
        background: darken($success, 10%)
      }

      h1 {
        color: $success;
      }

      span.block-tile__cta--numbers {
        color: $success;
      }
    }

    &--white {
      background: $white;

      h1 {
        color: $muted;
      }

      h4 {
        color: $muted;
        text-transform: uppercase;
      }

      h6 {
        color: $muted;

        a {
          color: $muted;
          text-decoration: underline;
        }
      }

      p {
        color: $muted;
      }
    }

  }
}

.property-block {
  @include border-radius(5px);
  margin: 0 0 20px;
  width: 100%;
  flex:1;
  background-color: $white;
  //-webkit-box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
  //-moz-box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
  //box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);

  img {
    // padding: 0.5rem;
    &#profile {
      display: block;
      max-width: 100%;
      //max-height: 100%;
      margin: 0 auto;
    }
  }

  &__content {
    padding: 1.3rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    label {
      font-size: 1.125rem;
      color: $primary;
    }

    a {
      color: $main-font;
    }

    h4 {
      line-height: 1;
      color: $primary;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    &--blocks {
      margin: 0 0 10px;

      h4 {
        font-size: 1rem;
        line-height: 1;
        color: $primary;
        margin-bottom: 5px;
        text-transform: none;
      }

      p {
        line-height: 20px;
        margin: 0px;
      }
    }
  }

  img {
    max-height: 100%;
    max-width: 100%;
    background: $white;
  }
}

.image-div {
    height: 310px;
    overflow: hidden;
    text-align: center;
  }
.image-div,
.image-holder {
  padding: 10px;
}

.image-holder {
  text-align: center;
  height: 80px;

}
@media only screen and (max-width: 1370px) {
  .image-div {
    max-height: 200px;
  }
}
@media only screen and (max-width: 479px) {
  .image-div {
    max-height: 170px;
    padding: 0;
  }
}
