/* New Account */

.new-accounts {
  overflow: hidden;
  position: relative;
  ul.chats {
    height: 100%;
    padding: 0;
    margin-bottom: 0;
    overflow-x: hidden;
    li.chat-persons {
      padding: 15px 0;
      display: block;
      border-bottom: $border-property;
      &:last-child {
        border-bottom: none;
      }
      .btn {
        &.btn-sm {
          padding: 0.2rem 0.75rem;
        }
      }
      a {
        @extend .d-flex;
        @extend .align-items-center;
        text-decoration: none;
        span.pro-pic {
          display: inline-block;
          padding: 0;
          width: 20%;
          max-width: 40px;
          img {
            max-width: 100%;
            width: 100%;
            border-radius: 100%;
          }
        }
        div.user {
          width: 60%;
          @extend .d-flex;
          @extend .flex-column;
          padding: 5px 10px 0 15px;
          p.u-name {
            margin: 0;
            color: $black;
            @extend %ellipsor;
          }
          p.u-designation {
            margin: 0;
            color: $black;
            @extend .text-small;
            @extend %ellipsor;
          }
        }
        p.joined-date {
          text-align: right;
          margin-left: auto;
          margin-bottom: 0;
          @extend .text-small;
          @extend .text-gray;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
    }
  }
}

.pagination {
    width: 100%;
    @include rel;
    padding: 5px 75px;
    background-color: $white;
    text-align: center;
    margin: 20px 0 0;
    &__link {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 1;
        color: $main-font;
        font-size: 14px;
        line-height: 1;
        display: block;
        font-weight: $f-black;
        padding: 10px;
        text-transform: uppercase;
        flex-grow: 1;
        @include on-event {
            color: $primary;
            text-decoration: none;
            &--prev svg {
                margin-left: -5px;
            }
            &--next svg {
                margin-right: -5px;
            }
        }
        &--prev {
            border-right: 1px solid #dfdede;
            left: 0;
        }
        &--next {
            border-left: 1px solid #dfdede;
            right: 0;
        }
    }
    &__pages {
        margin: 0 auto;
        padding: 12px 0;
        ol {
            @include inline;
            @include no-format;
            li {
                @include inline;
                vertical-align: middle;
                margin: 0 10px 5px 0;
                @include on-event {
                    a {
                        background-color: $primary;
                        color: $white;
                        text-decoration: none;
                    }
                }
                &.active span {
                    background-color: $primary;
                    color: $white;
                }
                a,
                span {
                    display: block;
                    color: $main-font;
                    font-size: 18px;
                    line-height: 1;
                    background-color: #efefef;
                    @include border-radius(5px);
                    padding: 6px 10.38px;
                    text-align: center;
                    font-weight: $f-black;
                }
            }
        }
    }
    @media only screen and (max-width: 959px) {

    }
}

