/* Cookie Policy */

div.cookie-consent {
  background-color: $primary;
  color: #fff;
  border-radius: 16px;
  bottom: 3rem;
  box-shadow: 0 4px 26px rgb(0 0 0 / 16%);
  display: flex;
  flex-direction: column;
  left: 1rem;
  padding: 2rem;
  position: fixed;
  right: 1rem;
  z-index: 60;
  height: auto !important;

  @media (min-width: 500px){
    bottom: 7rem;
    left: 2rem;
    max-width: 30.625rem;
    right: auto;
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
  button {
    border: none;
  }
}

div#manage-cookies {
  .modal-content {
    background-color: $primary;
    color: #fff;
    border: none;
    border-radius: 16px;
    box-shadow: 0 4px 26px rgb(0 0 0 / 16%);
    .modal-header {
      background-color: $primary;
      color: #fff;
      border: none;
      border-radius: 16px 16px 0px 0px;
    }
    .modal-body {
      background-color: $primary;
      color: #fff;
      border: none;
      border-radius: 16px 0px 0px 16px;
      padding: 15px 26px;
      h4 {
        color: $info;
      }
      a {
        color: #fff;
        text-decoration: underline;
      }
    }
    .modal-footer {
      background-color: $primary;
      color: #fff;
      border: none;
      border-radius: 0px 0px 16px 16px;
      button {
        border: none;
      }

    }
  }
}
