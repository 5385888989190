@media only screen and (max-width: 1350px) {
	.block-tile__cta {
		 h4 {
			//font-size: 0.937rem;
		}
		.btn span {
			font-size: 0.85rem;
		}
	}
}

@media only screen and (max-width: 1280px) {
	.widgets__block {
		.col-3 {
			max-width: 33.33%;
			flex: 0 0 33.33%;
		}
	}
	.block-tile__account .icon__static {
		height: 80% !important;
	}
}

@media only screen and (min-width: 1101px) {

	.main-nav {
		display: block !important;
	}

	// Bootstrap Grid Default Overrides
	$grid-gutter-width: 30px !default;
}
@media only screen and (max-width: 1100px) {

	.admin__content--wrapper {
		> .col-12 {
			margin: 0 auto !important;
			> .col-4,
			.col-8 {
				max-width: 100%;
				flex: 0 0 100%;
				padding: 0;
				margin: 0 auto;
			}
			> .col-12 {
				> .col-3 {
					flex: 0 0 33.33%;
					max-width: 33.33%;
				}
			}
		}
	}

	.break-flex__mobile {
		display: block !important;
		margin: 0 0 20px;
		.col-5 {
			max-width: 100%;
		}
		.directDebitG {
			object {
				width: 100% !important;
			}
		}
		.mb-20 {
			margin: 0 0 20px;
			padding: 0;
			&:last-of-type {
				margin: 0;
			}
		}
	}

	.images-outer {
		width: 24%;
		.image-remove {
		    width: 40px;
		    float: right;
		}
	}
	.site-header {
		&__main {
			width: 100%;
			float: none;
			@include rel;
			padding: 20px 15px;
			background-color: $white;
		}
		&__logo {
			height: 100% !important;
			a {
				margin: 0rem;
			}

		}
	}
	.site-header__icons {
		width: 100%;
		float: none;
	}
	.sidebar__hidden {
		top: 112px;
		width: 100%;
		&--inner {
			height: calc(100vh - 185px);
		}
	}
	.main-nav {
		display: none;
		float: none;
		position: absolute;
		top: 86px;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 10;
		height: 100%;
		text-align: center;
		.btn {
			float: none !important;
			margin: 10px;
			border-color: $white;
		}
		ul {
			display: block;
		}
	}
	.card {
		.card-body {
			form {
				.row.pb-2.pr-0 {
					flex-direction: column;
					margin: 0;
					.col {
						button[type="submit"] {
							margin: 0 0 5px 0;
						}
						.btn {
							margin: 0 0 5px;
						}
					}
				}
				.align__self {
					margin: 10px 0 0;
				}
				.text-right {
					text-align: left !important;
				}
			}
		}
	}
	#toggle {
		margin:0;
		cursor: pointer;
		width: 35px;
		height: 35px;
		@include rel;
		z-index: 101;
		display: block !important;
		float: right;
		@include border-radius(50%);
		span,
		span:before,
		span:after {
			cursor: pointer;
			border-radius: 2px;
			height: 4px;
			width: 35px;
			background: $primary;
			position: absolute;
			display: block;
			content: '';
		}
		span {
			left:0;
			top:21px;
		}
		span:before {
			top: -12px;
		}
		span:after {
			bottom: -12px;
		}
		span,
		span:before,
		span:after {
			transition: all 100ms ease-in-out;
			-webkit-transition: all 100ms ease-in-out;
		}
		&.active span {
			background-color: transparent;
		}
		&.active span:before,
		&.active span:after {
			top: 0;
		}
		&.active span:before {
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
		}
		&.active span:after {
			transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
		}
	}

	// Bootstrap Grid Default Overrides
	$grid-gutter-width: 20px !default;

	.widgets__block > .container-fluid {
		padding: 0;
	}

	.mobile-padding {
		padding: 0 0 10px !important;
	}

}

@media only screen and (max-width: 959px) {

	// Other Brands Login Page
	.loginBody {
		width:80% !important;
		margin-Bottom: 5rem;
	}


	.card-title {
		> .col-sm-6 {
			margin: 0 0 20px;
		}
	}

	// .widgets__block {
	// 	padding: 1.25rem !important;
	// }

	.statement-form {
		position: static;
	}

	.site-content__statements {
		padding: 1.25rem !important;
	}

	.button__block {
		.block-tiles.row {
			margin:0 auto !important;
			.col-4 {
				max-width: 100%;
				flex: 0 0 100%;
			}
		}
	}

	.add-margin-mobile {
		margin-bottom: 10px;
	}

	.pagination {
		padding: 5px 55px;
		&.shadow {
		margin: 0 auto;
		}
		&__link {
			font-size: 10px;
		}
		&__pages {
			ol li {
				a,
				span {
					font-size: 12px;
				}
			}
		}
	}

	// Bootstrap Grid Default Overrides
	$grid-gutter-width: 10px !default;

}

@media only screen and (max-width: 768px) {
	.widgets__block {
		.col-3 {
			max-width: 50%;
			flex: 0 0 50%;
		}
	}

	.footer__dashboard {
		.row {
			@include flex-direction(column);
			.col-4 {
				max-width: 100%;
				margin: 0 auto 10px;
			}
		}
	}

	.images-outer {
		width: 48%;
	}

	.admin__content--wrapper {
		> .col-12 > .col-8 > .col-12 {
			> .col-3 {
				max-width: 50%;
				flex: 0 0 50%;
				margin-bottom: 10px;
			}
		}
		> .col-12 {
			padding: 0;
			> .col-12 {
				padding: 0;
				margin: 0 auto;
				> .col-3 {
					flex: 0 0 50%;
					max-width: 50%;
				}
			}
		}
	}

	.container-fluid.grid-margin > .col-12.pl-0.pr-0 {
		display: block !important;
		.col-6 {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}



}

@media only screen and (max-width: 667px) {
	.directDebitG {
		height: 250px !important;
	}

	.tooltips {
		margin-left: -145px;
	}

	.site-content__statements .account-summary-block .btn {
		min-width: 100%;
	}

	.break-for-mobile {
		display: block !important;
		.col-6 {
			text-align: left !important;
			margin: 0 0 10px;
		}
		//On the Choose Unite Page - Payments
		.col-3 {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}
}


@media only screen and (max-width: 479px) {
	// Bootstrap Grid Default Overrides
	$grid-gutter-width: 5px !default;

	.widgets__block {
		padding: 0 !important;
		.col-3 {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}
	.images-outer {
		width: 100%;
	}

	.block-tile {
		margin: 0;
	}

	.admin__content--wrapper {
		> .col-12 > .col-8 > .col-12 {
			> .col-3 {
				max-width: 100%;
				flex: 0 0 100%;
				padding: 0 !important;
			}
		}
		> .col-12 {
			> .col-12 {
				> .col-3 {
					flex: 0 0 100%;
					max-width: 100%;
					padding: 0 !important;
				}
			}
		}
	}

	.loginBody {
		transform: none !important;
		-webkit-transform: none !important;
		position: relative !important;
		top:10px !important;
		left:0 !important;
		width:90% !important;
		margin-bottom: 7rem;
	}

	#loginFoot {
		a, p {
			font-size: 0.7rem !important;
		}
	}

}






