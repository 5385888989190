/* Typography */

:root,
body {
  font-size: 14px;
  font-family: $type-1;
  color: $main-font;
}

a {
    text-decoration: none;
    @include transition(.4s);
    @include on-event {
        text-decoration: underline;
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $type-1;
  font-weight: 500;
}

p {
  font-size: $default-font-size;
}

.tableHeading {
  margin: 20px 0;
}

.h1,
h1 {
  font-size: 2rem;
}

.h2,
h2 {
  font-size: 1.88rem;
}

.h3,
h3 {
  font-size: 1.56rem;
}

.h4,
h4 {
  font-size: 1.13rem;
}

.h5,
h5 {
  font-size: 1rem;
}

.h6,
h6 {
  font-size: 0.9375rem;
}

a {
  color: $primary;
}

.page-title {
    color: #818181;
    font-size: 24px;
    line-height: 1.1;
    margin: 0 0 20px;
    @include rel;
    @include inline;
    text-align: left;
    padding: 0 15px;
    &:after {
        content: "";
        background-color: #818181;
        position: absolute;
        right: 0;
        bottom: -5px;
        left: 15px;
        width: 170px;
        height: 2px;
    }
}

.block-title {
  font-size: 20px;
    line-height: 1;
    margin: 0 0 20px;
    text-transform: uppercase;
    font-weight: 900;
}

.display-1 {
  font-size: 3.75rem;
  @media (max-width: 991px) {
    font-size: 3rem;
  }
}

.display-2 {
  font-size: 3.125rem;
  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
}

.display-3 {
  font-size: 2.5rem;
  @media (max-width: 991px) {
    font-size: 2rem;
  }
}

.display-4 {
  font-size: 1.875rem;
  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
}

.display-5 {
  font-size: 1.25rem;
  @media (max-width: 991px) {
    font-size: 1rem;
  }
}

.blockquote {
  padding: 1.25rem;
  border: 1px solid $border-color;
}

address {
  p {
    margin-bottom: 0;
  }
}

//blockqoute color variations
@each $color,
$value in $theme-colors {
  .blockquote-#{$color} {
    @include blockquote($value);
  }
}

.page-title {
  color: $black;
  margin: 0.38rem 0 0.75rem;
}

.card-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #ffffff;
  text-transform: capitalize;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  padding: 0.8rem;
  background: $white;
  margin-bottom: 0px !important;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  .rtl & {
    text-align: right;
  }
  svg {
    margin-right:10px;
  }
  h1, h2, h3, h4, p {
    margin-bottom:0px;
    color: $primary;
  }
  @media only screen and (max-width: 959px) {
    display: block;
    .col-sm-6 {
      max-width: 100%;
    }
  }
}

.card-subtitle {
  @extend .text-gray;
  font-family: $type-1;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.card-description {
  margin-bottom: 0.9375rem;
  font-family: $type-1;
  .rtl & {
    text-align: right;
  }
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

small,
.text-small {
  font-size: 12px;
}

.icon-lg {
  font-size: 2.5rem;
}

.icon-md {
  font-size: 1.875rem;
}

.icon-sm {
  font-size: 1rem;
}

.fa_icon::before {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

hr {
  border-top: 1px solid $border-color;
}
