/* Breadcrumbs */

.breadcrumbs__custom {
    padding: 10px;
    background-color: $white;
    @include border-radius(5px);
    margin: 0 0 10px;
    ol {
      @include no-format;
      li {
        @include inline;
        vertical-align: middle;
      }
    }
    a,
    span {

        font-weight: $f-black;
        font-size: 14px;
        text-transform: uppercase;
    }
    a,
    svg {
        margin: 0 5px 0 0;
    }
    a {
        display: block;
        color: $main-font;
        @include on-event {
            text-decoration: none;
            color: $primary;
        }
    }
    svg {
        font-size: 12px;
    }
    span {
        color: $primary;
    }
}

.breadcrumb {
  border-radius: 0.0rem;
  margin-bottom: 0rem;
  padding: 0.56rem 7rem 0.56rem 1.13rem;
  background: $primary;
  .breadcrumb-item {
    font-size: $breadcrumb-font-size;
    &.active {
      color:#ffffff;
      font-weight: bold;
    }
    a {
      color:#ffffff;
    }
  }
  &.breadcrumb-custom {
    padding: $breadcrumb-custom-padding-y $breadcrumb-custom-padding-x;
    border-color: $breadcrumb-item-bg;
    .breadcrumb-item {
      font-size: $breadcrumb-font-size;
      background: $breadcrumb-item-bg;
      padding: $breadcrumb-custom-item-padding-y $breadcrumb-custom-item-padding-x;
      color: $breadcrumb-custom-item-color;
      display: inline-block;
      vertical-align: top;
      &:last-child {
        background: transparent;
      }
      &:before {
        content: "";
      }
      a {
        position: relative;
        color: inherit;
        border: 1px solid $breadcrumb-item-bg;
        display: inline-block;
        vertical-align: top;
        &:after,
        &:before {
          position: absolute;
          top: -9px;
          width: 0;
          height: 0;
          content: "";
          border-top: 21px solid transparent;
          border-bottom: 21px solid transparent;
        }
        &:before {
          right: -22px;
          z-index: 3;
          border-left-color: $breadcrumb-item-bg;
          border-left-style: solid;
          border-left-width: 12px;
        }
        &:after {
          border-top: 21px solid transparent;
          border-bottom: 22px solid transparent;
          border-left: 12px solid $white;
          top: -9px;
          right: -23px;
        }
      }
      span {
        display: inline-block;
        vertical-align: top;
      }
      &.active {}
    }
  }
  &.bg-danger,
  &.bg-dark,
  &.bg-info,
  &.bg-primary,
  &.bg-success,
  &.bg-warning {
    border: none;
    .breadcrumb-item {
      color: $white;
      &:before {
        color: inherit;
      }
      a,
      span {
        color: inherit;
      }
    }
  }
}

/* inverse breadcrumb */

@each $color,
$value in $theme-colors {
  .bg-inverse-#{$color} {
    @include breadcrumb-inverse-variant($value);
  }
}
