/* Auth */

.auth {
	&.auth-bg-1 {
		background: $background;
		transition: background 0.25s ease;
		-webkit-transition: background 0.25s ease;
		-moz-transition: background 0.25s ease;
		-ms-transition: background 0.25s ease;
		background-size: cover;
	}
	&.register-bg-1 {
		background: $background;
		transition: background 0.25s ease;
		-webkit-transition: background 0.25s ease;
		-moz-transition: background 0.25s ease;
		-ms-transition: background 0.25s ease;
		background-size: cover;
	}
	&.theme-one {
		.auto-form-wrapper {
			text-align: center;
			background: $white;
			padding: 25px 40px 10px;
			@include border-radius(4px);
			box-shadow: 0 -25px 37.7px 11.3px rgba(8, 143, 220, 0.07);
			font-size: 1rem;
			.login-logo {
				margin-bottom:20px;
			}
			.form-group {
				label, input {
					font-size: 1rem;
				}
				label {
					margin: 0 0 20px;
				}
				input {
					font-size: 1.5rem;
					height:50px;
					line-height:50px;
				}
				.input-group {
					.form-control {
						border: 1px solid darken($border-color, 5%);
						&:focus {
							border-color: darken($border-color, 5%);
						}
					}
					.input-group-append {
						.input-group-text {
							border-left: none;
							border-color: darken($border-color, 5%);
							color: #b6b6b6;
						}
					}
				}
				.btn {
					font-family: $type-1;
					font-size: 1.4rem;
					padding: 12px 8px;
					font-weight: $f-black;
					width: 100%;
					@include on-event {
						svg {
							color: $primary;
						}
					}
					svg {
						@include transition(.4s);
						font-size: 20px;
						color: $white;
						float: right;
					}
				}
				label.error {
					p {
						margin-bottom: 1rem !important;
						&:last-of-type {
							margin-bottom: 0rem !important;
						}
					}
				}
			}
			.g-login {
				border: 1px solid $border-color;
				padding: 13px;
				font-size: 12px;
				font-weight: 600;
				background: transparent;
			}
			.forgot_password {
				font-size: 1rem;
			}
		}
		.auth-footer {
			list-style-type: none;
			padding-left: 0;
			margin-top: 20px;
			margin-bottom: 10px;
			@include display-flex;
			@include justify-content(center);
			li {
				margin-right: 10px;
				line-height: 1;
				padding-right: 10px;
				border-right: 1px solid rgba(255, 255, 255, 0.4);
				&:last-child {
					margin-right: 0;
					border-right: none;
				}
				a {
					font-size: 13px;
					color: rgba(255, 255, 255, 0.4);
				}
			}
			@include media-breakpoint-down(sm) {
				@include justify-content(center);
			}
		}
		.footer-text {
			color: rgba(255, 255, 255, 0.4);
			margin-top:10px;
		}
	}
	&.theme-two {
		.auto-form-wrapper {
			position: relative;
			height: 100vh;
			min-height: 100vh;
			max-height: 100vh;
			padding: 110px 5% 5%;
			@include border-radius(4px);
			@include media-breakpoint-down(sm) {
				padding: 11% 15px;
				text-align: center;
				height: 100%;
				max-height: 100%;
			}
			.nav-get-started {
				@include display-flex;
				@include align-items(center);
				@include justify-content(flex-end);
				position: absolute;
				top: 30px;
				right: 30px;
				@include media-breakpoint-down(sm) {
					margin-bottom: 5%;
					margin-right: auto;
					margin-left: auto;
					position: relative;
					top: unset;
					right: unset;
					@include justify-content(center);
				}
				p {
					margin-bottom: 0;
					font-weight: 300;
				}
				.get-started-btn {
					border: 1px solid $border-color;
					padding: 10px 20px;
					font-size: 12px;
					font-weight: 600;
					color: $black;
					margin-left: 20px;
					@include border-radius(50px);
				}
			}
			form {
				width: 50%;
				min-width: 300px;
				max-width: 480px;
				.form-group {
					width: 100%;
					margin-bottom: 25px;
					@include media-breakpoint-down(sm) {
						margin-right: auto;
						margin-left: auto;
					}
					.input-group {
						height: 44px;
						.form-control {
							border: 1px solid darken($border-color, 5%);
							border-left: none;
							@include border-radius(0 6px 6px 0);
							&:focus {
								border-left: none;
								border-color: darken($border-color, 5%);
							}
						}
						.input-group-prepend {
							.input-group-text {
								@include border-radius(6px 0 0 6px);
								border-color: darken($border-color, 5%);
								border-right: none;
								color: #dfdfdf;
							}
						}
					}
					.submit-btn {
						font-family: $type-1;
						font-size: 13px;
						padding: 11px 33px;
						font-weight: 600;
						background-image: $blue-gradient;
					}
				}
			}
			.footer-text {
				font-size: 13px;
				margin-bottom: 0;
				margin-top:10px;

			}
			.auth-footer {
				list-style-type: none;
				@include display-flex;
				margin-top: 7px;
				padding-left: 0;
				margin-bottom: 0;
				li {
					margin-right: 10px;
					line-height: 1;
					padding-right: 10px;
					border-right: 1px solid $text-muted;
					&:last-child {
						margin-right: 0;
						border-right: none;
					}
					a {
						font-size: 13px;
						color: $text-muted;
					}
				}
				@include media-breakpoint-down(sm) {
					@include justify-content(center);
				}
			}
		}
		.banner-section {
			padding-right: 0;
			.slide-content {
				width: 100%;
				&.bg-1 {
					background-size: cover;
				}
				&.bg-2 {
					background-size: cover;
				}
			}
		}
	}
}

