.login-page {
	background-color: $white;
	@include rel;
	height: 100vh;
	.container {
		@media only screen and (max-width: 959px) {
			max-width: 100%;
		}
	}
	header {
		&.top_center {
			text-align: center;
		}
		&.top_left {
			text-align: left;
		}
		img {
			max-height: 15rem;
			padding: 2rem 1rem;
			max-width: 100%;
		}
	}
	.site-content__register {
		.site-content__login--login {
			label {
				margin: 0 0 5px;
			}
			input {
				margin: 0 0 10px;
			}
		}
		.container {
			@media only screen and (max-width: 959px) {
				max-width: 100%;
			}
		}
	}
	.site-content__login {
		height: calc(100% - 125px);
		margin: 0 auto;
		width: 100%;
		text-align: center;
		&--wrapper,
		&--images {
			@media only screen and (max-width: 1600px) {
				max-width: 100%;
				flex: 0 0 100%;
			}
		}
		&--images {
			@media only screen and (max-width: 1600px) {
				padding-bottom: 35px;
			}
		}
		@media only screen and (max-width: 1024px) {
			width: 95%;
			padding: 12.5px 0;
		}
		@media only screen and (max-width: 959px) {
			height: 100%;
			&--welcome,
			&--login {
				width: 100% !important;
				padding: 20px 20px 40px;
				@include border-radius(5px);
			}
			//&--welcome {
			//	margin: 0 0 20px;
			//}
			&--login {
				h2 {
					margin: 0 0 10px !important;
				}
			}
		}
		&--images {
			> .container {
				display: table;
				height: 100%;
			}
			figure {
				display: table-cell;
				vertical-align: middle;
			}
		}
		p {
			font-size: 16px;
			line-height: 1.5;
			color: $main-font;
		}
		&--welcome,
		&--login {
			width: 50%;
			float: left;
		}
		&--welcome {
			background-color: $light-blue;
			padding: 2rem;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			text-align: left;
			h2 {
				line-height: 1;
				margin: 0 0 1rem;
				color: $primary;
				font-weight: $f-black;
				text-transform: uppercase;
				@media only screen and (max-width: 959px) {
					font-size: 26px;
					margin: 0 0 20px;
				}
			}
			> p a {
				color: $primary;
				font-weight: $f-black;
				text-decoration: underline;
				@include on-event {
					text-decoration: none;
				}
			}
			label {
				display: block;
				margin: 0 0 0.8rem;
				color: $white;
				font-size: 18px;
				line-height: 1;
				font-weight: $f-black;
			}
			input {
				height: 54px;
				padding: 0 18px;
				border: 1px solid #ccc;
				@include border-radius(5px);
				width: 100%;
				margin: 0 0 25px;
				background-color: #fbfbfb;
			}
			.login-intro {
				@include rel;
				margin: 0 0 2rem;
				&::before,
				&::after {
					content: "";
					position: absolute;
					left: 0;
					height: 1px;
					width: 100px;
				}
				&::after {
					background-color: $primary;
				}
			}
			h3 {
				font-size: 24px;
				line-height: 1;
				margin: 0 0 40px;
				color: #1eacd5;
				font-weight: $f-black;
			}
			.btn {
				font-size: 14px;
				width: 100%;
				svg {
					float:right;
				}
			}
		}
		&--live_chat {
			background-color: $light-blue;
			padding: 40px;
			border-radius: 5px;
			text-align: left;
			a.btn {
				font-size:2rem !important;
				span {
					font-size:2rem !important;
				}
			}
			h2 {
				line-height: 1;
				margin: 0 0 40px;
				color: $primary;
				font-weight: $f-black;
				text-transform: uppercase;
				@media only screen and (max-width: 959px) {
					font-size: 26px;
					margin: 0 0 20px;
				}
			}
			> p a {
				color: $primary;
				font-weight: $f-black;
				text-decoration: underline;
				@include on-event {
					text-decoration: none;
				}
			}
			label {
				display: block;
				margin: 0 0 20px;
				color: $white;
				font-size: 18px;
				line-height: 1;
				font-weight: $f-black;
			}
			input {
				height: 54px;
				padding: 0 18px;
				border: 1px solid #ccc;
				@include border-radius(5px);
				width: 100%;
				margin: 0 0 25px;
				background-color: #fbfbfb;
			}
			.login-intro {
				@include rel;
			}
			h3 {
				font-size: 24px;
				line-height: 1;
				margin: 0 0 40px;
				color: #1eacd5;
				font-weight: $f-black;
			}
			.btn {
				font-size: 14px;
				width: 100%;
				svg {
					float:right;
				}
			}
		}
		&--login {
			background-color: $info;
			padding: 2rem;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			text-align: left;
			@include rel;
			h2 {
				color: $white;
				font-weight: $bold;
				line-height: 1;
				font-weight: $f-black;
				margin: 0 0 1rem;
				text-transform: uppercase;
			}
			label {
				display: block;
				margin: 0 0 0.8rem;
				color: $white;
				font-size: 18px;
				line-height: 1;
				font-weight: $f-black;
			}
			input {
				height: 54px;
				line-height: 54px;
				padding: 0 18px;
				border: 1px solid #ccc;
				@include border-radius(5px);
				background-color: #fbfbfb;
				margin: 0 0 1.5rem;
			}
			.input-group {
				margin: 0 0 1.5rem;
				input {
					margin: 0px;
				}
			}
			button.btn {
				width: 100%;
				margin-top: 25px;
				display: flex;
				flex-direction: row;
				text-align: center;
				justify-content: center;
				align-items: center;
				@include on-event {
					svg {
						color: $primary;
					}
				}
			}
			.btn {
				svg {
					float:right;
				}
			}

			.forgot-pw {
				position: relative;
				right: 0;
				bottom: auto;
				left: 0;
				text-align: center;
				display: block;
				color: $white;
				font-weight: $f-black;
				@include on-event {
					color: darken($primary, 10%);
				}
			}
		}
		&--reset {
			background-color: $info;
			padding: 40px;
			border-radius: 15px 15px 0px 0px;
			text-align: center;
			position: relative;
			width: 100%;
			@include rel;
			h2 {
				color: $primary;
				font-weight: $bold;
				font-size: 30px;
				line-height: 1;
				font-weight: $f-black;
				margin: 0 0 20px;
				text-transform: uppercase;
				text-align: center;
			}
			h4 {
				color: $primary;
			}
			label {
				color: $white;
				font-size: 18px;
			}
			p, li {
				color: $white;
			}
			input {
				height: 54px;
				padding: 0 18px;
				border: 1px solid #ccc;
				@include border-radius(5px);
				width: 100%;
				margin: 0 0 10px;
				background-color: #fbfbfb;
			}
			button {
				display: block;
				width: 100%;
				margin-top: 0px;
				@include on-event {
					svg {
						color: $success;
					}
				}
				span {
					font-size: 18px;
					line-height: 1.3;
				}
				svg {
					float: right;
					font-size: 24px;
					color: $white;
				}
			}
			.btn {
				svg {
					float:right;
				}
			}
			.forgot-pw {
				position: absolute;
				right: 0;
				bottom: 40px;
				left: 0;
				width: 100%;
				text-align: center;
				display: block;
				color: $white;
				font-weight: $f-black;
				@include on-event {
					color: darken($primary, 10%);
				}
			}
		}
		&--help {
			.left {
				width: 190px;
				background-color: $warning;
				padding: 25px;
				@include rel;
				display: table;
				border-bottom-left-radius: 15px;
				text-align: center;
				@media only screen and (max-width: 959px) {
					border-radius: 0px 0px 15px 15px;
				}

				&::after {
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 20px 0 20px 24.2px;
					border-color: transparent transparent transparent $warning;
					position: absolute;
					top: 50%;
					right: -14px;
					margin-top: -20px;
					z-index: 2;
				}
				h6 {
					margin: 0;
					font-size: 18px;
					line-height: 1;
					color: $white;
					font-weight: $f-black;
					display: table-cell;
					vertical-align: middle;
					text-transform: uppercase;
				}
			}
			.right {
				width: calc(100% - 190px);
				display: flex;
				@include rel;
				.cell {
					display: table-cell;
					vertical-align: middle;
					p {
						@include inline;
						margin: 0;
						font-size: 16px;
						line-height: 1.2;
						text-align: left;
						padding-left: 10px;
					}
					.btn {
						@include on-event {
							svg {
								color: $primary;
							}
						}
						span {
							font-size: 14px;
						}
						svg {
							color: $white;
							margin-left:10px;
						}
						@media only screen and (max-width: 959px) {
							position: static;
						}
					}
				}
			}
			@media only screen and (max-width: 959px) {
				.left,
				.right {
					display: block;
					// float: none;
					width: 100% !important;
					text-align: center;
				}
				.left {
					&:after {
						top: auto;
						right: 50%;
						bottom: -25px;
						margin-right: -20px;
						@include rotate(90deg);
					}
					h6 {
						display: block;
					}
				}
				.right {
					padding: 30px 0px 15px !important;
					.cell {
						p {
							display: block;
							text-align: center;
							width: 100%;
							margin: 0 auto 20px;
						}
						.btn {
							float: none;
							margin: 0 auto;
						}
					}
				}
			}
		}
	}
	footer {
		width: 100%;
		background-color: $background-alt;
		padding: 10px 15px;
		z-index:2;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-shrink: 0;
		a,
		span {
			font-size: 16px;
			line-height: 1;
		}
		.left {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.right {
			margin-left:auto;
			span {
				font-weight: $med;
				color: $main-font;
			}
		}
		.btn {
			font-size: 14px;
			width: auto;
			svg {
				float:right;
				margin-left:5px;
			}
		}
		@media only screen and (max-width: 959px) {
			// position: static;
		}
		@media only screen and (max-width: 667px) {
			position: relative;
			width:100%;
			.left,
			.right {
				float: none;
				width: 100%;
				text-align: center;
			}
			.left {
				display: block;
				margin: 0 auto 10px;
			}
			.btn-group {
				flex-grow: 1;
				margin: 0px;
			}
			.btn {
				font-size: 12px;
				padding: 10px;
				width: 100%;
				span {
					font-size: 12px;
				}
				svg {
					float:right;
					font-size: 12px;
				}
			}


		}
	}
	.loginFoot {
		position: fixed;
		bottom: 0;
		width: 100%;
		padding: 10px 20px;
		z-index: 3;
		margin: 0px !important;
	}
}

//Error 503 page styling.
.page-503 {
	height: 100%;
	.row-middle {
		height: 100%;
		justify-content: center;
		align-items: center;
	}
	.col-6 {
		img {
			max-width: 800px;
			margin: 0 auto;
			width: 100%;
			text-align: center;
			display: block;
		}
	}
	@media only screen and (max-width: 1024px) {
		height: auto;
		.col-6 {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
}

// Other Brand Login Pages

.login-page {
	min-height: 100vh;
	position: relative;

	.site-content__login {
		text-align: left;

		&--welcome {
			border-radius: 15px 0px 0px 0px;
			@media only screen and (max-width: 959px) {
				border-radius: 15px 15px 0px 0px;
			}
			background: $light-blue;
			display: flex;
			flex-direction: column;
			&--app {
				background-color: $info;
				border-radius: 15px 0px 0px 15px !important;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 1rem 2rem;
				@media only screen and (max-width: 959px) {
					border-radius: 15px 15px 0px 0px !important;
					padding: 1rem 2rem;
				}
				h4 {
					text-align: center;
					text-transform: uppercase;
					font-weight: 500;
					margin: 0px;
				}
			}
		}

		&--login {
			border-radius: 0px 15px 0px 0px;
			@media only screen and (max-width: 959px) {
				border-radius: 0px;
			}
			display: flex;
			flex-direction: column;
			&--app {
				border-radius: 0px 15px 15px 0px !important;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				padding: 1rem 2rem;
				@media only screen and (max-width: 959px) {
					border-radius: 0px 0px 15px 15px !important;
					padding: 1rem 2rem;
				}
				@media only screen and (max-width: 667px) {
					flex-direction: column;
					.btn {
						width: 100%;
						margin: 0.5rem 0rem;
					}

				}
				.btn {
					flex: 1;
				}
			}
			label {
				color: $light-blue !important;
			}
		}

		&--help {
			.left {

				&:after {
				}

			}

			.right {
				width: calc(100% - 190px);
				display: flex;
				align-items: center;
				@include rel;
				font-size: 18px;
				border-bottom-right-radius: 15px;
				@media only screen and (max-width: 959px) {
					border-radius: 15px;
				}

				p {
					@include inline;
					margin: 0;
					font-size: 16px;
					line-height: 1.2;
					text-align: left;
					padding: 20px 50px;
					flex: 3;
					height: 100%;
					background-color: $background-alt;
					@media only screen and (max-width: 959px) {
						border-radius: 15px 0px 0px 15px;
					}

				}
				.btn {
					border-radius: 0px;
					height: 100%;
					align-items: center;
					display: flex;
					text-align: center;
					justify-content: center;
					border-bottom-right-radius: 15px;
					@media only screen and (max-width: 959px) {
						border-radius: 0px 15px 15px 0px;
					}

					width: auto;
					svg {
						float:right;
						padding-left: 10px;
					}
				}

			}
		}
	}
}

#loginWrap{
	flex: 1 0 auto;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	.loginLogo{
		display: flex;
		img {
			width: 20rem;
			padding: 0.7rem;
		}
	}
	.loginBody{
		background: transparent !important;
		flex: 1;
		z-index:3;
		border-radius: 5px;
		padding: 2rem;
		.loginHead{
			padding: 0.7rem 0rem !important;
			background: $white;
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			align-items: center;
			img{
				display: block;
				margin:0 auto;
				max-width: 340px;
				//max-height: 60px;
			}
		}
		.loginInner{
			overflow: hidden;
			position: relative;
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 15px;
			.loginContent{
				padding:20px;
				text-align: center;
				border: 0px;
				color: #fff;
				h3{
					margin-top:0px;
					font-size: 1.8em;
				}
				p{
					color: #fff;
				}
				p:last-of-type{
					margin-bottom: 0px;
				}
			}
			.loginTabs{
				.formSubmit{
					position: relative;
					.submitBtn{
						float: right;
						text-transform: uppercase;
						width: 40%;
						border-radius: 0px;
						//padding: 25px;
						&:hover{
						}
					}
				}
				.tabInner{
					&.help{
						text-align: center;
					}
				}
				.btmLogin{
					text-align: center;
					margin-top: 20px;
					p{
						margin-bottom:0px;
					}
				}
				.input-container{
					margin: 0px 0px 20px 0px;
					position: relative;
					.helphov{
						position: absolute;
						right: 8px;
						top: 43px;
						height: 45px;
						width: 45px;
						text-align: center;
						padding-top: 10px;
						border-top-right-radius: 0px;
						border-bottom-right-radius: 0px;
						border-radius: 100%;
						i{
							font-size: 1.6em;
						}
						&:hover{
							cursor: pointer;
						}
					}
					.helpInfo{
						position: absolute;
						right: -190px;
						top: 0;
						max-width: 180px;
						background: #efefef;
						border-radius: 3px;
						border: 1px solid #b7b7b7;
						padding: 10px;
						font-size: 0.9em;
						display: none;
						&:after, &:before{
							right: 100%;
							top: 16px;
							border: solid transparent;
							content: " ";
							height: 0;
							width: 0;
							position: absolute;
							pointer-events: none;
						}
						&:after{
							border-color: rgba(239, 239, 239, 0);
							border-right-color: #efefef;
							border-width: 15px;
							margin-top: -15px;
						}
						&:before{
							border-color: rgba(183, 183, 183, 0);
							border-right-color: #b7b7b7;
							border-width: 17px;
							margin-top: -17px;
						}
					}
					&:last-of-type{
						margin:0px;
					}
					label{
						width:100%;
						float: left;
						padding-top:0px;
						font-size: 1.1em;
						font-weight: normal;
						display: block;
						margin-bottom: 10px;
						text-align: left;
						a{
							float: right;
							text-decoration: underline;
						}
					}
					input{
						width:100%;
						float: left;
						border-radius: 0px;
						border: 0px solid #b7b7b7;
						padding: 25px;
						height: auto;
					}
				}
				.tabs {
					li {
						float:left;
						width:50%;
					}
					a {
						display:inline-block;
						text-align:center;
						text-decoration:none;
						background:transparent;
						width:100%;
						padding:20px;
						font-size: 1.4em;
						margin-bottom: -1px;
						position: relative;
						z-index: 2;
						color: #3e3e3e;
						&:hover {
							background:transparent;
						}
						&.active{
							border:0px solid #b7b7b7;
							border-bottom: 0px;
							background: transparent;
						}
					}
				}
				.tabWrap {
					border:0px;
				}
			}
			ul.no-bullet {
				list-style-type: none;
				padding-left: 0px;
				li {
					font-weight: 300 !important;
					h4 {
						font-weight: 300 !important;
					}
				}
			}
		}
		&--help {
			.loginInner{
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
			}
		}

	}

}

.hero__content {
	background-color:rgba(0, 0, 0, 0.3);
}

// Errors
.parsley-errors-list {
	li {
		padding: 10px;
		background: $danger;
		color: $white;
		border-radius: 5px;
		margin-bottom:10px;
	}
}
