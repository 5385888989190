.site-header {
	box-shadow: 0px 0px 3px 0px rgba(75, 79, 109, 0.5);
	background-color: $white;
	&__main {
		padding: 0px 33.5px;
		background-color: $white;
		width: 82%;
		.btn {
			max-width: 140px;
			min-width: 140px;
			@include on-event {
				svg {
					color: $info;
				}
			}
			svg {
				color: $white;
			}
		}
		a {
			&:hover {
				text-decoration: none !important;
			}
		}
    }
	&__logo {
		display: inline-block;
		text-align: center;
		position: relative;
		height: 112px;
		vertical-align: center;
		a {
			margin: 0rem 2rem;
		}
		&:before{
			content: "";
			display: inline-block;
			vertical-align: middle;
			height: 100%;
		}
	}
	&__home {
		font-size: 0;
		background-color: #fafafa;
		a {
			background-color: #fafafa;
			font-size: 25px;
			border-left: 1px solid #cccccc;
			border-right: 1px solid #cccccc;
			@include inline;
			width: 100%;
			@include rel;
			height: 112px;
			color: #7f7f7f;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			svg, img {
				display: block;
				-webkit-transition: all 0.4s ease-in-out;
				transition: all 0.4s ease-in-out;
			}
			@include on-event {
				text-decoration: none !important;
				svg {
					color: $primary;
				}
				label {
					color: $primary;
				}
			}
			svg {
				color: #7f7f7f;
			}
			label {
				color: #7f7f7f;
			}
		}
	}
	&__icons {
		width: 18%;
		font-size: 0;
		background-color: $white;
		a {
			@include inline;
			background-color: #fafafa;
			font-size: 25px;
			width: 50%;
			text-align: center;
			@include rel;
			height: 112px;
			color: #7f7f7f;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			@include on-event {
				background-color: $white;
				text-decoration: none !important;
				svg {
					color: $primary;
				}
			}
			svg, img {
				@include transition(.4s);
			}
			&.active {
				box-shadow: 0 -3px 2px 0px rgba(75, 79, 109, 0.5);
				z-index: 51;
			}
		}
		&--middle,
		&--last {
			border-left: 1px solid #cccccc;
		}
	}
}

.main-nav {
	background-color: $info;
	ul {
		@include no-format;
		display: flex;
		flex-direction: column;
		li {
			text-align: center;
			@include rel;
			&::before,
			&::after {
				content: "";
				height: 1px;
				width: 80px;
				left: 50%;
				margin-left: -40px;
				display: block;
				position: absolute;
			}
			&::before {
				background-color: #3e4159;
				bottom: 0;
			}
			&::after {
				background-color: #515574;
				bottom: -1px;
			}
			@include on-event {
				a {
					background-color: #3e4159;
					text-decoration: none;
				}
			}
			a {
				padding: 1.55rem;
				text-align: center;
				display: block;
				font-weight: $f-black;
				color: $white;
				font-size: 14px;
				&.active {
					background-color: #3e4159;
				}
				svg {
					@include transition(.4s);
					color: $primary;
					//font-size: 50px;
					margin: 0 auto 10px;
					display: block;
				}
				span {
					color: $white;
					@include transition(.4s);
					font-size: 14px;
					line-height: 1.1;
				}
			}
		}
	}
	@media only screen and (max-width: 959px) {

	}
}
