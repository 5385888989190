.calendar {
  font-family: $type-1;
  .fc-toolbar {
    font-family: $type-1;
    background-color: $primary;
    color: $white;
    margin-bottom: 0px !important;
    padding: 0.5em !important;
    .fc-toolbar-title {
      font-size: 1rem;
    }
    button {
      border-color: $white;
    }
  }
  .fc-col-header, .fc-timegrid-axis, .fc-list-day-cushion {
    background-color: $muted;
    color: $white;
  }
  .fc-view-harness {
    color: $main-font;
    .fc-timegrid-slot {
      height: 1rem;
    }

    //
    .fc-daygrid, .fc-timegrid {
      background-color: $white;
      .fc-daygrid-event {
        background-color: $primary !important;
        color: $white !important;
      }
      .fc-timegrid-event {
        background-color: $primary !important;
        color: $white !important;
      }
    }
    // list view
    .fc-list{
      border: none !important;
      table {
        color: $white;
        font-family: $type-1;
        border: none !important;
        td, th {
          border: none !important;
        }
        .fc-event, .fc-event-dot, .fc-list-event td {
          background-color: $primary !important;
        }
      }
    }
  }
}
