@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: ($opacity * 100);
  filter: #{alpha(opacity=$opacity-ie)};
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin transition($transition...) {
	-webkit-transition: all $transition ease-in-out;
	-moz-transition: all $transition ease-in-out;
	-o-transition: all $transition ease-in-out;
	transition: all $transition ease-in-out;
}

@mixin transition-property($transition-property...) {
-webkit-transition-property: $transition-property;
transition-property: $transition-property;
}

@mixin transition-delay($transition-delay) {
	-webkit-transition-delay: $transition-delay;
	transition-delay: $transition-delay;
}

@mixin transition-duration($transition-duration...) {
	-webkit-transition-duration: $transition-duration;
	transition-duration: $transition-duration;
}

@mixin transition-transform($transition...) {
	-webkit-transition: -webkit-transform $transition;
	-moz-transition: -moz-transform $transition;
	-o-transition: -o-transform $transition;
	transition: transform $transition;
}

@mixin box-shadow($top, $left, $blur, $color, $inset:"") {

	-webkit-box-shadow:$top $left $blur $color #{$inset};
	-moz-box-shadow:$top $left $blur $color #{$inset};
	box-shadow:$top $left $blur $color #{$inset};

}

@mixin rotate ($rotate) {
	-webkit-transform: rotate($rotate);
    -ms-transform: rotate($rotate);
    transform: rotate($rotate);
}

@mixin svg_img($img_path) {
	background-image: url(#{$img_path}.svg);
	background-repeat: no-repeat;

	.lt-ie9 &,
	.no-svg &{
			background-image: url(#{$img_path}.png);
	}
}

@mixin bg {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

@mixin inline {
	display: inline-block;
	zoom:1;
	*display:inline;
}

@mixin no-format {
	list-style:none;
	padding:0;
	margin:0
}

@mixin indent {
	text-transform:capitalize;
	text-align:left;
	text-indent:-99999px
}

@mixin rel {
	position:relative
}

@mixin box {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

@mixin flex {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

@mixin flex-box {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

@mixin cf {
	&:before,
	&:after {content: " ";display: table}
	&:after {clear: both}
}
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
	&:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
	&:focus {
      @content;
    }
  }
}

@mixin shadow {
  -webkit-box-shadow: 0px 0px 9px 3px rgba(207,219,232,1);
  -moz-box-shadow: 0px 0px 9px 3px rgba(207,219,232,1);
  box-shadow: 0px 0px 9px 3px rgba(207,219,232,1);
}
@mixin shadow2 {
	-webkit-box-shadow: 0px 0px 9px 3px rgba(234,234,234,1);
	-moz-box-shadow: 0px 0px 9px 3px rgba(234,234,234,1);
	box-shadow: 0px 0px 9px 3px rgba(234,234,234,1);
}
