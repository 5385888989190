/*------------------------------------------------------------------
 [Master Stylesheet]

  Project:	Star Admin Bootstrap Template [Free Version]
  Version:	2.0.0
-------------------------------------------------------------------*/

/*-------------------------------------------------------------------
  ===== Table of Contents =====

  * Bootstrap functions
  * Template variables
  * SCSS Compass Functions
  * Boostrap Main SCSS
  * Template mixins
    + Animation Mixins
    + Background Mixins
    + BlockQuote Mixins
    + Badges Mixins
    + Buttons Mixins
    + Cards Mixins
    + Miscellaneous Mixins
    + Text Mixins
  * Core Styles
    + Reset Styles
    + Fonts
    + Functions
    + Sidebar
    + Navbar
    + Typography
    + Miscellaneous
    + Footer
    + Layouts
    + Utilities
    + Demo styles
    + Dashboard
  * Components
    + Badges
    + Bootstrap Progress
    + Buttons
    + Cards
    + Checkboxes and Radios
    + Dropdowns
    + Forms
    + Icons
    + Lists
    + Nav
    + New Account
    + Preview
    + Tables
  * Landing screens
    + Auth
    + Error
  * Header
    + Header
    + Hidden Sidebar
  * Block Tiles
    + Block Tiles
-------------------------------------------------------------------*/

/*-------------------------------------------------------------------*/

/* === Import Bootstrap functions and variables === */

@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
/*-------------------------------------------------------------------*/

/* === Import template variables === */

@import "wellesley_variables";
/*-------------------------------------------------------------------*/

/* === SCSS Compass Functions === */

@import "../../../../../node_modules/compass-mixins/lib/compass";
@import "../../../../../node_modules/compass-mixins/lib/animate";
/*-------------------------------------------------------------------*/

/* === Boostrap Main SCSS === */

@import "../../../../../node_modules/bootstrap/scss/bootstrap";
/*-------------------------------------------------------------------*/

/* === Template mixins === */

@import "../../mixins/animation";
@import "../../mixins/accordions";
@import "../../mixins/background";
@import "../../mixins/badges";
@import "../../mixins/blockqoute";
@import "../../mixins/breadcrumbs";
@import "../../mixins/buttons";
@import "../../mixins/cards";
@import "../../mixins/misc";
@import "../../mixins/no-ui-slider";
@import "../../mixins/pagination";
@import "../../mixins/text";
@import "../../mixins/popovers";
@import "../../mixins/tabs";
@import "../../mixins/tooltips";
@import "../../mixins/global-mixins";
/*-------------------------------------------------------------------*/

/* === Core Styles === */

@import "../../core/reset";
@import "../../core/fonts";
@import "../../core/functions";
@import "../../core/sidebar";
@import "../../core/navbar";
@import "../../core/typography";
@import "../../core/misc";
@import "../../core/footer";
@import "../../core/utilities";
@import "../../core/demo";
@import "../../core/dashboard";
/*-------------------------------------------------------------------*/

/* === Vendors and Node Modules === */
@import "../../../../../node_modules/select2/dist/css/select2.css";
@import "../../../../../node_modules/daterangepicker/daterangepicker.css";

@import "../../../../../node_modules/fullcalendar/main.css";
@import "../../../../../node_modules/jquery-contextmenu/dist/jquery.contextMenu.min.css";



/*-------------------------------------------------------------------*/
/* === Components === */

@import "../../components/accordions";
@import "../../components/badges";
@import "../../components/bootstrap-alerts";
@import "../../components/bootstrap-progress";
@import "../../components/buttons";
@import "../../components/breadcrumbs";
@import "../../components/calendar";
@import "../../components/cards";
@import "../../components/checkbox-radio";
@import "../../components/cookie-policy";
@import "../../components/datepicker";
@import "../../components/dropdown";
@import "../../components/forms";
@import "../../components/google-maps";
@import "../../components/icons";
@import "../../components/lists";
@import "../../components/loaders/loaders";
@import "../../components/modals";
@import "../../components/nav";
@import "../../components/new-account";
@import "../../components/pagination";
@import "../../components/popovers";
@import "../../components/portfolio";
@import "../../components/preview";
@import "../../components/pricing-table";
@import "../../components/tables";
@import "../../components/tabs";
@import "../../components/settings-panel";
@import "../../components/todo-list";
@import "../../components/tooltips";
@import "../../components/user-profile";
@import "../../components/widgets";
@import "../../components/fancybox";
@import "../../components/loading-screen";
/*-------------------------------------------------------------------*/

/* === Landing screens === */

@import "../../landing-screens/auth";
@import "../../landing-screens/error";
@import "../../landing-screens/login-page"; //Includes some SCSS for register page also.
/*-------------------------------------------------------------------*/

/*-------------------------------------------------------------------*/

/* === Header === */

@import "../../header/header";
@import "../../header/hidden-sidebar";

/*-------------------------------------------------------------------*/

/* === Block Tiles === */

@import "../../block-tiles/block-tiles";

/*-------------------------------------------------------------------*/

/* === Pages === */

@import "../../pages/payments";
@import "../../pages/statements";

/*-------------------------------------------------------------------*/

/* === Responsive === */

@import "../../core/responsive";

/*-------------------------------------------------------------------*/



// body, html {
//   font-size: 0.85em;
// }

.navbar {
  &.fixed-top {
    +.page-body-wrapper {
      padding-top: $navbar-height;
    }
  }
}

.disabled-button {
  pointer-events: none;
  opacity: 0.4;
}



.fc-license-message {
  display: none !important;
}

// Sidebar Mini
.sidebar-mini {
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-mini;
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-mini});
      }
    }
    .sidebar {
      width: $sidebar-width-mini;
      .nav {
        .nav-item {
          .nav-link {
            display: flex;
            align-content: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            .menu-title {
              font-size: 14px;
              font-weight: bold;
              display: inline-block;
            }
            .badge {
              margin-left: 5px;
              display: none;
            }
            img {
              display: block;
              margin-right: auto;
              margin-left: auto;
              margin-bottom: 10px;
            }
            .menu-arrow {
              position: absolute;
              right: 7px;
            }
            .menu-icon {
              margin-right: 0;
            }
          }
          &.nav-profile {
            .nav-link {
              justify-content: center;
              .profile-name {
                display: none;
              }
            }
            i {
              margin-left: 1rem;
            }
          }
          .sidebar-sticker {
            display: none;
          }
        }
      }
    }
    .main-panel {
      width: calc(100% - #{$sidebar-width-mini});
    }
    &:not(.sidebar-icon-only) {
      //Apply these styles only when sidebar-mini is not collapsed to icon-only mode
      .sidebar {
        .nav {
          &:not(.sub-menu) {
            >.nav-item {
              border-top: 1px solid rgba($sidebar-dark-menu-color, .2);
              &.nav-doc,
              &.nav-profile {
                border-top: 0;
              }
              .nav-link {
                height: auto;
                padding: $sidebar-mini-menu-padding;
                i {
                  &.menu-icon {
                    margin-bottom: 0.5rem;
                  }
                }
              }
            }
          }
          &.sub-menu {
            padding: 0 0.5rem;
            .nav-item {
              .nav-link {
                padding: 1rem 0;
                text-align: center;
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Sidebar Icon Only
.sidebar-icon-only {
  .alert {
    padding: 0;
  }
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-icon;
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: flex;
        }
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-icon});
      }
    }
    .sidebar {
      width: $sidebar-width-icon;
      .nav {
        overflow: visible;
        .nav-item {
          position: relative;
          .nav-link {
            display: block;
            text-align: center;
            padding-left: 20px;
            padding-right: 20px;
            .badge,
            .menu-title {
              font-size: 14px;
              font-weight: bold;
              display: none;
            }
            i {
              &.menu-icon {
                margin-right: 0;
              }
              &.menu-arrow {
                display: none;
              }
            }
          }
          &.nav-profile,
          .sidebar-sticker {
            display: none;
          }
          .collapse {
            display: none;
          }
          &.hover-open {
            .nav-link {
              .menu-title {
                font-size: 14px;
                font-weight: bold;
                display: flex;
                align-items: center;
                background: $sidebar-light-menu-hover-bg;
                color: $sidebar-light-menu-color;
                @at-root #{selector-append(".sidebar-dark", &)} {
                  background: $sidebar-dark-menu-hover-bg;
                  color: $sidebar-dark-menu-color;
                }
                padding: 0.5rem 1.25rem;
                left: $sidebar-width-icon;
                position: absolute;
                text-align: left;
                top: 0;
                width: $sidebar-icon-only-submenu-width;
                z-index: 1;
                height: $nav-link-height;
                line-height: 1.8;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right: $sidebar-width-icon;
                  text-align: right;
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                }
                &:after {
                  display: none;
                }
              }
              &[data-toggle=collapse] {
                .menu-title {
                  font-size: 14px;
                  font-weight: bold;
                  border-bottom-right-radius: 0;
                  @at-root #{selector-append(".rtl", &)} {
                    border-bottom-left-radius: 0;
                  }
                }
              }
            }
            .collapse,
            .collapsing {
              display: block;
              padding: 0.5rem 0;
              background: $sidebar-light-menu-hover-bg;
              color: $sidebar-light-menu-color;
              @at-root #{selector-append(".sidebar-dark", &)} {
                background: $sidebar-dark-menu-hover-bg;
                color: $sidebar-dark-menu-color;
              }
              position: absolute;
              top: $nav-link-height;
              left: $sidebar-width-icon;
              width: $sidebar-icon-only-submenu-width;
              border-bottom-right-radius: 5px;
              @at-root #{selector-append(".rtl", &)} {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 5px;
              }
              @at-root #{selector-append(".rtl", &)} {
                left: auto;
                right: $sidebar-width-icon;
              }
              -webkit-box-shadow: 0 1px 15px 1px rgba(113, 106, 202, .08);
              -moz-box-shadow: 0 1px 15px 1px rgba(113, 106, 202, .08);
              box-shadow: 0 1px 15px 1px rgba(113, 106, 202, .08);
            }
          }
        }
        &.sub-menu {
          padding: $sidebar-icon-only-submenu-padding;
          .nav-item {
            .nav-link {
              text-align: left;
              color: $sidebar-light-menu-color;
              &:hover {
                color: darken($white, 20%);
              }
              @at-root #{selector-append(".sidebar-dark", &)} {
                color: $sidebar-dark-menu-color;
              }
              @at-root #{selector-append(".rtl", &)} {
                text-align: right;
              }
            }
            &:nth-child(5n+1),
            &:nth-child(5n+2),
            &:nth-child(5n+3),
            &:nth-child(5n+4),
            &:nth-child(5n+5) {
              .nav-link {
                &:before {
                  background: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Hidden Sidebar
.sidebar-hidden {
  @media (min-width: 992px) {
    .sidebar {
      transition: width $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function;
      width: 0;
    }
    .main-panel {
      width: 100%;
    }
  }
}

// Absolute sidebar with overlay to content
.sidebar-absolute {
  @media (min-width: 992px) {
    .page-body-wrapper {
      position: relative;
      .sidebar {
        transition: none;
      }
    }
    &:not(.sidebar-hidden) {
      .sidebar {
        position: absolute;
        height: 100%;
        -webkit-box-shadow: 0 0 3px 1px #a7a3a3;
        /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        -moz-box-shadow: 0 0 3px 1px #a7a3a3;
        /* Firefox 3.5 - 3.6 */
        box-shadow: 0 0 3px 1px #a7a3a3;
        /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
      }
    }
    .main-panel {
      width: 100%;
      transition: none;
    }
  }
}

//Fixed sidebar
.sidebar-fixed {
  @media(min-width: 992px) {
    .sidebar {
      position: fixed;
      max-height: auto;
      .nav {
        max-height: calc(100vh - #{$navbar-height});
        overflow: auto;
        position: relative;
        &.sub-menu {
          max-height: none;
        }
      }
    }
    .main-panel {
      margin-left: $sidebar-width-lg;
    }
    &.sidebar-icon-only {
      .main-panel {
        margin-left: $sidebar-width-icon;
      }
    }
  }
}

//Boxed layout
.boxed-layout {
  @media (min-width: 992px) {
    background: lighten($muted, 50%);
    .container-scroller {
      width: 100%;
      max-width: $boxed-container-width;
      margin-left: auto;
      margin-right: auto;
    }
    .navbar {
      width: 100%;
      max-width: $boxed-container-width;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

//RTL layout
.rtl {
  direction: rtl;
  text-align: right;
  .sidebar {
    .nav {
      padding-right: 0;
      .nav-item {
        .nav-link {
          i {
            &.menu-icon {
              margin-right: 0;
              margin-left: 0.5em;
            }
            &.menu-arrow {
              margin-left: 0;
              margin-right: auto;
            }
          }
          .badge {
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
      &.sub-menu {
        padding: $rtl-sidebar-submenu-padding;
      }
    }
  }
  .product-chart-wrapper,
  .settings-panel .tab-content .tab-pane .scroll-wrapper,
  .sidebar-fixed .nav,
  .table-responsive,
  ul.chats {
    &::-webkit-scrollbar {
      width: 0.5em;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
}

.adminBlock {
  display: block;
  span.lrgIcon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    padding:10px;
    background: #0878CA;
    border-radius: 2px;
    color: #fff;
    font-size: 3.2em;
    text-align: center;
    display: inline-block;
  }
  .inner {
    display: inline-block;
    padding: 15px;
    float: left;
    width: calc(100% - 240px);
    height:70px;
    h3 {
      margin-top: 0px;
      color: #0878CA;
      margin-bottom: 0px;
      line-height: 40px;
    }
    h4 {
      margin:11px 0;
      color: #0878CA;
    }
    p {
      color: #0878CA;
    }
  }
  &:hover {
    //background: rgba(22, 166, 219, 0.1);
    border-radius: 3px;
    color: #fff;
    h3, h4, p {
      color: #fff;
    }
    svg.lrgIcon {
      background: #0878CA;
    }
  }
}
textarea.form-control.input-disabled{
  background-color:#FFF !important;
}

@font-face {
  font-family:"calibri";
  src:url("https://use.typekit.net/af/5c4f69/00000000000000007735bbfb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/5c4f69/00000000000000007735bbfb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/5c4f69/00000000000000007735bbfb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:400;
}

h1,
h2,
h3,
h4,
h5,
p {
  font-family: "calibri";
  font-weight: normal;
  //color: #fff;
}

p {
  font-family: "calibri";
}

h4.smlTitle {
  font-size: 1.4em;
  color: $success;
}

ul {
  font-family: "calibri";
}

ul li {
  //color: #fff;
}

ul li strong {
  //color: #fff;
  font-family: "calibri";
}

.innerBtn {
  background: $success;
  color: #fff;
  padding: 5px 10px;
  display: inline-block;
}

.innerBtn:hover {
  background: $success;
  color: #fff;
}

body {
  background: #efefef;
  font-family: "calibri" !important;
  font-weight: normal !important;
}

ul {
  padding: 0px;
  margin: 0px;
}

ul li {
  list-style: none;
}

.animateMe {
  transition: all 0.5s;
}

.submitBtn {
  background: #39b54a;
  border: none;
  border-radius: 3px;
  padding: 15px 40px;
  font-family: "calibri";
  color: #fff;
  font-size: 1.2em;
}

.submitBtn:hover {
  background: #54cc64;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.submitBtn i {
  margin-left: 10px;
}

.defBtn {
  background: $success;
  border: none;
  border-radius: 0px;
  padding: 15px 40px;
  font-family: "calibri";
  color: #fff;
  font-size: 1.2em;
  display: inline-block;
}

.defBtn:hover {
  background: $success;
  text-decoration: none;
  color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.defBtn i {
  margin-left: 10px;
}

.lineIcnBtn {
  display: inline-block;
  border: 2px solid $success;
  font-family: "calibri";
  color: $success;
  border-radius: 0px;
  padding: 10px 20px;
  font-size: 1.2em;
  background: transparent;
}

.lineIcnBtn i {
  margin-right: 10px;
}

.lineIcnBtn.sml {
  font-size: 1em;
  padding: 5px 15px;
}

.lineIcnBtn:hover {
  background: $success;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  color: #fff !important;
  text-decoration: none;
}

.lineIcnBtn.rightMarg {
  margin-right: 20px;
}

.lineIcnBtn.rightI i {
  margin-right: 0px;
  margin-left: 10px;
}

.lineIcnBtn.Red {
  border: 2px solid red;
  color: red;
}

.lineIcnBtn.Red i {
  color: red;
}

.lineIcnBtn.Red:hover {
  background: red;
  color: #fff;
}

.lineIcnBtn.Red:hover i {
  color: #fff;
}

.lineIcnBtn.Round {
  border-radius: 100%;
  height: 30px;
  width: 30px;
  text-align: center;
  padding: 3px 0px 0px 0px;
}

.lineIcnBtn.Round i {
  margin: 0px;
}

.upload-btn {
  position: relative;
  overflow: hidden;
  margin: 10px;
}

.upload-btn input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.btn {
  background: silver;
}

.btn:hover {
  background: $success;
  color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.btn.twitter {
  background: #1da1f2;
}

.btn.twitter:hover {
  background: #1e8ccf;
}

.btn.secondary {
  background: buttonface;
  border: 1px solid transparent;
}

.btn.rightMarg {
  margin-right: 20px;
}

.btn.rightI i {
  margin-right: 0px;
  margin-left: 10px;
}

.btn.Grn {
  background: $success;
  border: 1px solid $success;
}

.btn.Grn:hover {
  background: $success;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.btn i {
  margin-right: 10px;
}

.nrmlBtn {
  background: $success;
  display: inline-block;
  font-family: "calibri";
  padding: 10px 20px;
  border-radius: 0px;
  font-size: 1.2em;
  border: 1px solid $success;
  color: #fff !important;
}

.nrmlBtn:hover {
  background: $success;
  color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.nrmlBtn.twitter {
  background: #1da1f2;
}

.nrmlBtn.twitter:hover {
  background: #1e8ccf;
}

.nrmlBtn.rightMarg {
  margin-right: 20px;
}

.nrmlBtn.rightI i {
  margin-right: 0px;
  margin-left: 10px;
}

.nrmlBtn.Grn {
  background: $success;
  border: 1px solid $success;
}

.nrmlBtn.Grn:hover {
  background: $success;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.nrmlBtn i {
  margin-right: 10px;
}

.fullbtn {
  color: #fff;
  background: $success;
  width: 100%;
  font-family: "calibri";
  padding: 20px;
  display: block;
  text-align: center;
  font-size: 1.2em;
}

.fullbtn:hover {
  background: $success;
  color: #fff;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
  color: $success;
}

.rightAlign {
  text-align: right;
}

.rightAlign.topMarg {
  margin-top: 20px;
}

.cenAlign {
  text-align: center;
}

.cenAlign th,
.cenAlign td {
  text-align: center;
}

.text-red {
  color: #ff0000;
}
.text-green {
  color: #00ff00;
}

/* ==========================================================================
Login Screen Styles
========================================================================== */
.login-page {
  background: url("../../../images/brands/wellesley/hero-bg-image.jpg") bottom center / cover no-repeat $dark;
  min-height: 100vh;
  position: relative;
  .site-content__login {
    text-align: left;
    &--welcome {
      border-radius: 0px;
      background: $light-blue;
      label {
        color: $primary !important;
      }
    }
    &--login {
      border-radius: 0px;
      p, h4, li {
        color: $white;
      }
      label {
        color: $light-blue !important;
      }
    }
    &--help {
      .left{
        background-color: $success;
        &:after {
          border-color: transparent transparent transparent $success;
        }

      }
    }
    &--reset {
      h2 {
        color: $white;
      }
    }
  }
  .alerts__content {
    padding: 23px 28px 23px 23px;
    width: calc(100% - 60px);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
  }
}

#loginWrap{
  .loginBody{
    background: $white;
    z-index:3;
    .loginHead{
      padding: 10px 0px;
      background-color: #ffffff;
      img{
        display: block;
        margin:0 auto;
        max-width: 240px;
      }
    }
    .loginInner{
      background: $primary;
      .loginContent{
        background: $primary;
        padding:20px;
        text-align: center;
        border: 0px;
        color: #fff;
        h3{
          margin-top:0px;
          font-size: 1.8em;
          color: $primary;
        }
        p{
          color: #fff;
        }
        p:last-of-type{
          margin-bottom: 0px;
        }
      }
      .loginTabs{
        .formSubmit{
          position: relative;
          .submitBtn{
            float: right;
            text-transform: uppercase;
            background: $success;
            font-family: $primaryFont, FontAwesome;
            width: 40%;
            border-radius: 0px;
            //padding: 25px;
            &:hover{
              background: $success;
            }
          }
        }
        .tabInner{
          &.help{
            text-align: center;
            color: #fff;
          }
        }
        .btmLogin{
          text-align: center;
          margin-top: 20px;
          p{
            margin-bottom:0px;
          }
        }
        .input-container{
          margin: 0px 0px 20px 0px;
          position: relative;
          .helphov{
            position: absolute;
            right: 8px;
            top: 43px;
            height: 45px;
            width: 45px;
            background: #b7b7b7;
            text-align: center;
            color: #fff;
            padding-top: 10px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-radius: 100%;
            i{
              font-size: 1.6em;
            }
            &:hover{
              background: $primary;
              cursor: pointer;
            }
          }
          .helpInfo{
            position: absolute;
            right: -190px;
            top: 0;
            max-width: 180px;
            background: #efefef;
            border-radius: 3px;
            border: 1px solid #b7b7b7;
            padding: 10px;
            font-family: $primaryFont;
            font-size: 0.9em;
            display: none;
            &:after, &:before{
              right: 100%;
              top: 16px;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
            }
            &:after{
              border-color: rgba(239, 239, 239, 0);
              border-right-color: #efefef;
              border-width: 15px;
              margin-top: -15px;
            }
            &:before{
              border-color: rgba(183, 183, 183, 0);
              border-right-color: #b7b7b7;
              border-width: 17px;
              margin-top: -17px;
            }
          }
          &:last-of-type{
            margin:0px;
          }
          label{
            width:100%;
            float: left;
            padding-top:0px;
            font-family: $secondaryFont;
            font-size: 1.1em;
            color: $primary !important;
            font-weight: normal;
            display: block;
            margin-bottom: 10px;
            text-align: left;
            a{
              float: right;
              color: $primary;
              text-decoration: underline;
            }
          }
          input{
            width:100%;
            float: left;
            font-family: $primaryFont;
            border-radius: 0px;
            border: 0px solid #b7b7b7;
            padding: 25px;
            height: auto;
          }
        }
        .tabs {
          li {
            float:left;
            width:50%;
          }
          a {
            display:inline-block;
            text-align:center;
            text-decoration:none;
            background:transparent;
            width:100%;
            font-family: $primaryFont;
            padding:20px;
            font-size: 1.4em;
            margin-bottom: -1px;
            position: relative;
            z-index: 2;
            color: #3e3e3e;
            &:hover {
              background:transparent;
            }
            &.active{
              border:0px solid #b7b7b7;
              border-bottom: 0px;
              background: transparent;
              color: $primary;
              border-bottom: 2px solid $success;
            }
          }
        }
        .tabWrap {
          background: $background;
          border:0px;
        }
      }
    }
  }
}

#loginFoot{
  position: fixed;
  bottom: 0;
  width: 100%;
  background: $dark;
  padding:10px 20px;
  z-index:3;
  .footerNav{
    li{
      display: inline-block;
      a{
        font-family: $primaryFont;
        color: $primary;
      }
    }
  }
  p{
    margin-bottom: 0px;
    text-align: right;
  }
}


.hero__content {
  align-items:center;
  background-color:rgba(0, 0, 0, 0.5);
  bottom:0px;
  box-sizing:border-box;
  color:rgb(0, 0, 0);
  cursor:pointer;
  display:flex;
  font-family:$primaryFont, sans-serif;
  font-size:20px;
  font-weight:300;
  justify-content:center;
  left:0px;
  line-height:35px;
  padding-left:30px;
  padding-right:30px;
  padding-top:46px;
  position:absolute;
  right:0px;
  text-align:center;
  text-rendering:optimizelegibility;
  text-size-adjust:100%;
  top:0px;
  z-index:2;
  -webkit-font-smoothing:antialiased;
  -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
}

/* ==========================================================================
End Login Screen Styles
========================================================================== */
.site-header {
  background-color: $white;
  &__main {
    background-color: $white;
    .btn {
      @include on-event {
        svg {
          color: $white;
        }
      }
      svg {
        color: $white;
      }
    }
    a.btn--green {
      background: $primary;
      &:hover {
        background: lighten($primary, 10%);
      }
      svg {
        color: $white !important;
      }
      span {
        color: $white !important;
      }
    }
    a.btn--purple {
      background: $dark;
      &:hover {
        background: lighten($dark, 10%);
      }
      svg {
        color: $white !important;
      }
      span {
        color: $white !important;
      }
    }
  }
  &__home {
    background-color: $white;
    a {
      background-color: $white;
      border-left: 1px solid $primary;
      border-right: 1px solid $primary;
      @include inline;
      width: 100%;
      @include rel;
      color: $white !important;
      label {
        color: $white !important;
      }
      svg {
        color: $white !important;
      }
      @include on-event {
        background-color: darken($white, 5%);
        svg {
          color: $white !important;
        }
        label {
          color: $white !important;
        }
      }
    }
  }
  &__icons {
    background-color: $white;
    a {
      background-color: $white;
      border-left: 1px solid $primary;
      @include inline;
      @include rel;
      color:$white;
      label {
        color: $white !important;
      }
      @include on-event {
        background-color: darken($white, 5%);
        svg {
          color: $white !important;
        }
        label {
          color: $white !important;
        }
      }
      &:last-of-type {
        border-right: none;
      }
    }
  }
  &__no_sidebar {
    background-color: $primary;
    &__logo {
      background-color: $white;
    }
    &__home {
      background-color: $primary;
      a {
        background-color: $primary;
        svg {
          color: $white;
        }
        @include on-event {
          background-color: darken($primary, 5%);
          svg {
            color: $white;
          }
        }
      }
    }
    &__icons {
      background-color: $primary;
      a {
        background-color: $primary;
        svg {
          color: $white;
        }
        @include on-event {
          background-color: darken($primary, 5%);
          svg {
            color: $white;
          }
        }
      }
    }
  }
}

//.site-header {
//  &__logo {
//    img {
//      height: 89%;
//    }
//  }
//}
.block-tile__account .icon__static {
  color: $success;
}

.block-tile__colours--green {
  background-color: $success;
}

.block-tile__colours--red {
  background-color: $success;
  &:hover {
    background-color: $success;
  }
}

.block-tile__cta--notification--wrapper .block-tile__cta--numbers--red {
  color: $success;
}
.block-tile__cta--notification--wrapper .block-tile__cta--numbers--realred {
  color: #bf1611 !important;
}
.block-tile__cta--notification--wrapper .block-tile__cta--numbers--realgreen {
  color: #1f9033 !important;
}
/* Buttons */
.btn {
  font-size: $btn-font-size;
  line-height: 1;
  font-family: $type-1;
  text-transform: uppercase;
  font-weight: $f-black;
  font-size: 14px;
  line-height: 1;
  svg {
    color: $white;
  }
  &--white {
    background-color: $white !important;
    color: #606060 !important;
    border: 1px solid grey;
    span {
      color: #606060 !important;
      letter-spacing: 1px;
    }
  }
  &--loading {
    background: transparent;
    border: none;
    span {
      color: $info;
    }
  }
  &__download {
    background-color: transparent;
  }
  &--green {
    background-color: $success;
    border: 1px solid $success;
    color: $white;
    @include on-event {
      background-color: $white;
      svg {
        color: $success !important;
      }
      span {
        color: $success;
      }
    }
  }
  &--dark_blue {
    background-color: #0000F0;
    border: 1px solid #0000F0;
    color: $white;
    @include on-event {
      background-color: $white;
      svg {
        color: #0000F0;
      }
      span {
        color: #0000F0;
      }
    }
  }
  &--purple {
    background-color: $info;
    border: 1px solid $info;
    color: $white;
    @include on-event {
      background-color: $white;
      svg {
        color: $info;
      }
      span {
        color: $info;
      }
    }
  }
  &--orange {
    background-color: $warning;
    border: 1px solid $warning;
    color: $white;
    @include on-event {
      background-color: $white;
      svg {
        color: $warning;
      }
      span {
        color: $warning;
      }
    }
  }
  &--blue {
    background-color: $primary;
    border: 1px solid $primary;
    color: $white;
    @include on-event {
      background-color: $white;
      color: $primary !important;
      svg {
        color: $primary;
      }
      span {
        color: $primary;
      }
    }
  }
  &--blue-border {
    background-color: $white;
    border: 1px solid $primary;
    span {
      color: $primary;
    }
    svg {
      color: $primary !important;
    }
    @include on-event {
      background-color: $primary;
      svg {
        color: $white !important;
      }
      span {
        color: $white;
      }
    }
  }
  &.btn-danger {
    background-color: $danger;
    border-color: $danger;
    color: $white;
    @include on-event {
      background-color: $white;
      color: $danger;
      svg {
        color: $danger !important;
      }
      span {
        color: $danger;
      }
    }
  }
  &.btn-success {
    background-color: $success;
    border-color: $success;
    color: $white;
    @include on-event {
      background-color: $white;
      color: $success;
      svg {
        color: $success !important;
      }
      span {
        color: $success;
      }
    }
  }
  &.btn-warning {
    background-color: $warning;
    border-color: $warning;
    color: $white;
    @include on-event {
      background-color: $white;
      color: $warning;
      svg {
        color: $warning !important;
      }
      span {
        color: $warning;
      }
    }
  }
  svg {
    margin-right: 0.3125rem;
  }
  .btn-label {
    &:before {
      font-size: 1rem;
      line-height: 5px;
      vertical-align: middle;
    }
    &.btn-label-left {
      margin-right: 5px;
    }
    &.btn-label-right {
      margin-left: 5px;
    }
  }
  &.btn-rounded {
    border-radius: 50px;
  }
  &.btn-icons {
    width: 40px;
    height: 40px;
    padding: 10px;
    text-align: center;
    vertical-align: middle;
    svg {
      margin: auto;
      line-height: initial;
    }
  }
  &.btn-fw {
    min-width: $button-fixed-width;
  }
  &.icon-btn {
    svg {
      margin-right: 0;
    }
  }
  &.social-btn {
    padding: $social-btn-padding;
    svg {
      margin-right: 0;
      font-size: $social-btn-icon-size;
    }
  }
  &.btn-sm {
    font-size: $btn-font-size-sm;
  }
  &.btn-lg {
    font-size: $btn-font-size-lg;
  }
  &.btn-xs {
    padding: $btn-padding-y-xs $btn-padding-x-xs;
    font-size: $btn-font-size-xs;
    margin-bottom: 0.25rem !important;
  }
  &.btn-transparent {
    background: transparent;
  }
  &.btn-danger,
  &.btn-info,
  &.btn-success,
  &.btn-teal,
  &.btn-warning {
    color: $white;
  }
  &.btn-outline-light {
    border-color: darken(theme-color(light), 15%);
    color: darken(theme-color(light), 15%);
  }
  &.btn-outline-secondary {
    color: rgba($black, 0.5);
  }
  &.btn-inverse-secondary {
    background-color: rgba(theme-color(secondary), 0.5);
    color: rgba($black, 0.5);
    &:hover {
      color: rgba($black, 0.5);
    }
  }
  &.btn-inverse-light {
    background-color: $white;
    color: rgba($black, 0.5);
    border-color: lighten($black, 85%);
    &:hover {
      color: rgba($black, 0.5);
      border-color: lighten($black, 85%);
    }
  }
}

.btn-primary {
  background-color: $primary;
  border-color: $primary !important;

  &:hover {
    background-color: $white;
    color: $primary;
  }
}

.btn-info {
  background-color: $black;
  border-color: $black !important;

  &:hover {
    background-color: $white;
    color: $black;
  }
}

.block-tile__colours--green {
  background-color: $success;
  background: $success;
}

.block-tile__colours--purple {
  background-color: #5D6D7E;
  background: #5D6D7E;
}

.block-tile__colours--orange {
  background-color: $primary;
  background: lighten($primary, 10%);
}


.block-tile__colours--green:hover,
.block-tile__colours--green:active,
.block-tile__colours--green:focus {
  background: $success;
}

.block-tile__colours--green h1 {
  color: $success;
}

.site-content__payments--methods--wrapper.active {
  background-color: $primary;
}

.site-content__payments--methods--wrapper.active > div svg {
  color: $primary;
  background-color: #ffffff;
}

.payment-checkmark {
  background-color: $success;
  .payment-checkmark__inner {
    background-color: $success;
  }
}

// Loader
.circle-loader:after,
.circle-loader:before {
  border-top-color: $primary;
}

// DateRange Picker

.daterangepicker {
  font-family: $primaryFont;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: $primary;
}

.select-container:before {
  border-radius: 0px 5px 5px 0px;
}

.select2-container--rmg .select2-selection--single, .select2-container--rmg .select2-selection--multiple  {
  border-radius: 5px;
}

.select2-container--rmg .select2-selection--single .select2-selection__rendered, .select2-container--rmg .select2-selection--multiple .select2-selection__rendered {
  line-height: 38px;
  vertical-align: middle;
}

.select2-container--rmg .select2-selection--single, .select2-container--rmg .select2-selection--multiple, .select2-container--rmg .select2-selection--single .select2-selection__arrow, .select2-container--rmg .select2-selection--multiple .select2-selection__arrow {
}

.select2-container--open .select2-dropdown--below {
  border: 1px solid $primary !important;
  border-top: none !important;
}

.main-nav ul li a svg {
  color: $muted;
}

.main-nav ul li:hover a, .main-nav ul li:active a, .main-nav ul li:focus a {
  background: transparent !important;
}

//Announcements
.announcement {
  margin-top: 10px;
  padding: 0.7rem 0rem;
  letter-spacing: 0.05em;
  font-family: "Helvetica Neue",Roboto,Arial,"Droid Sans",sans-serif;

  &-read {
    font-weight: 300;
    color: grey;
  }

  &-unread {
    font-weight: 900;
    color: #424242;
  }
}

// Bootstrap Overrides
.text-primary {
  color: $primary !important;
}
