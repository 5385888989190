@mixin social-button($color) {
  background: $color;
  color: color(white);

  &:hover {
    background: darken($color, 10%);
  }

  &.btn-link {
    background: none;
    color: $color;
    border-bottom: solid 3px $color;
    &:hover {
      color: darken($color, 10%);
    }
  }
}

@mixin btn-social-outline-variant($color) {
  background: transparent;
  border: 1px solid;
  border-color: $color;
  color: $color;

  &:hover {
    background: rgba($color, 0.2);
  }
}

@mixin button-inverse-variant($color, $color-hover: $white) {
  color: $color;
  background-color: rgba($color, 0.2);
  background-image: none;
  border-color: rgba($color, 0);

  @include hover {
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }

  &.focus,
  &:focus {
    box-shadow: 0 0 0 3px rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &.active,
  &:active,
  .show>&.dropdown-toggle {
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }
}

@mixin button-inverse-outline-variant($color, $color-hover: $white) {
  color: $color;
  background-image: none;
  background: transparent;
  border: 1px solid;
  border-color: rgba($color, 0.2);

  @include hover {
    color: $color;
    background-color: rgba($color, 0.2);
    border-color: rgba($color, 0.2);
  }

  &.focus,
  &:focus {
    box-shadow: 0 0 0 3px rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &.active,
  &:active,
  .show>&.dropdown-toggle {
    color: $color-hover;
    border-color: $color;
  }
}

.btn {
  @include border-radius(5px);
  display: inline-block;
  text-align: center;
  span {
    color: $white;
    text-transform: uppercase;
    @include transition(.4s);
    font-weight: $f-black;
    font-size: 1rem;
    line-height: 1;
  }
  svg {
    @include transition(.4s);
  }
  &__small {
    min-width: 185px;
    padding: 12px;
    span {
      font-size: 18px;
    }
  }
  &__med {
    min-width: 248px;
    padding: 20px;
  }
  &__large {
    min-width: 395px;
    padding: 20px;
  }
}
