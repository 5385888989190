.blackbell-loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: rgba(33, 33, 33, 0.9);
    height: 100vh;
    width: 100%;
    display: none;
    text-align: center;
    color: #fff;


    &__inner {
        height: 100vh;
        img {
            height: 30vh;
            width: auto;
        }
        p {
            font-size: 30px;
        }
    }
}
.loading-div {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: rgba(33, 33, 33, 0.9);
    height: 100vh;
    width: 100%;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // Loader Circle
    .circle-loader {
        z-index: 11;
        margin: 0 auto 20px auto;
        // Loader Logo Image
        &__image{
            width: 50%;
            max-width: 250px;
        }
        &.active {
            display: block !important;
        }
    }
}
