.nav,
.navbar-nav {
  .nav-item {
    line-height: 1;
    &.dropdown {
      .dropdown-toggle {
        &:after {
          border: none !important;
          display: none !important;
          content: "" !important;
          font-size: 0px !important;
          text-rendering: auto;
          line-height: inherit;
          vertical-align: 0;
        }
      }
      .count-indicator {
        position: relative;
        text-align: center;
        svg {
          margin-right: 0;
          vertical-align: middle;
        }
        .count {
          position: absolute;
          left: 50%;
          width: 1rem;
          height: 1rem;
          border-radius: 100%;
          background: #FF0017;
          color: $white;
          font-size: 11px;
          top: -1px;
          font-weight: 600;
          line-height: 1rem;
          border: none;
          text-align: center;
        }
        &:after {
          display: none;
        }
      }
      svg {
        margin-right: 0.5rem;
        vertical-align: middle;
        .rtl & {
          margin-left: 0.5rem;
          margin-right: 0;
        }
      }
      .navbar-dropdown {
        color:#4a4a4a;
        font-size: 12px;
        margin-top: 0;
        position: absolute;
        top: calc(#{$navbar-height} - 6px);
        right: 0;
        left: auto;
        border: 1px solid rgba(182, 182, 182, 0.1);
        padding:0px;
        min-width: 100%;
        border-radius: 2px;
        @extend .dropdownAnimation;
        -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.13);
        -moz-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.13);
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.13);
        .rtl & {
          right: auto;
          left: 0;
        }
        overflow: hidden;
        @media (max-width: 991px) {
          right: -85px;
        }
        &.dropdown-left {
          left: 0;
          right: auto;
        }
        .badge {
          margin-left: 2.5rem;
          .rtl & {
            margin-left: 0;
            margin-right: 1.25rem;
          }
          @media (max-width:991px) {
            margin-right: 0.5rem;
            .rtl & {
              margin-left: 0.5rem;
              margin-right: 0;
            }
          }
        }
        .dropdown-item {
          @extend .d-flex;
          @extend .align-items-center;
          margin-bottom: 0;
          padding: 12px 25px;
          svg {
            color:#979696;
          }
          .ellipsis {
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .preview-icon {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .small-text {
            font-size: 0.75rem;
          }
        }
        .dropdown-divider {
          margin: 0;
        }
      }
    }
    .nav-link {}
  }
}
.nav-tabs {
  border-bottom: 2px $primary solid !important;
  .nav-item {
    .nav-link {
      padding: 20px 60px 15px 60px;
      border-radius: 1.5rem 1.5rem 0 0;
      border: 2px solid $primary;
      border-bottom-width: 0px;
      line-height: 2 !important;
      background-color: transparent;
      align-items: center;
      &:hover {
        border-color: $primary;
        background-color: $white;
      }
      .badge {
        font-size: 15px;
        line-height: 11px;
        background-color: $primary;
        color: $white;
      }
      span {
        font-weight: bold;
      }
      &.active{
        border-color: $primary;
        background-color: $primary;
        color: $white;
        .badge {
          background-color: $white;
          color: $primary;
        }
      }

    }
  }

  .nav-link.active {
  }

  .badge {
    font-size: 15px;
    line-height: 11px;
  }

}
